import * as React from "react";
import { FormControl, FormGroup, FormLabel, FormText } from "react-bootstrap";
import { WrappedFieldProps } from "redux-form";

interface IFormConfig {
  label: string;
  type?: string;
  placeholder: string;
  explanation?: string;
  maxLength?: number;
  horizontal?: boolean;
}

class BootstrapFormGroup extends React.Component<IFormConfig & WrappedFieldProps> {
  public render() {
    const { label, placeholder, explanation, maxLength, type, input, horizontal } = this.props;
    return (
      <FormGroup className={horizontal ? "row" : ""}>
        {label && <FormLabel className={horizontal ? "col-sm-2" : ""}>{ label }</FormLabel>}
        <div className={horizontal ? "col-sm-10" : ""}>
          <FormControl type={ type ? type : "text" } placeholder={ placeholder } maxLength = {maxLength} {...input} />
        </div>
        {explanation && <FormText className="text-muted">{explanation}</FormText>}
      </FormGroup>
    );
  }
}

export default BootstrapFormGroup;
