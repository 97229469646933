import { Reducer } from "redux";
import { EndpointActionTypes, IEndpointState } from "./types";

const initialState: IEndpointState = {
  data: [],
  errors: undefined,
  loading: false,
};

const reducer: Reducer<IEndpointState> = (state = initialState, action) => {
  switch (action.type) {
    case EndpointActionTypes.FETCH_REQUEST: {
      return { ...state, errors: undefined, loading: true };
    }
    case EndpointActionTypes.FETCH_SUCCESS: {
      return { ...state, loading: false, data: action.payload };
    }
    case EndpointActionTypes.FETCH_ERROR: {
      return { ...state, loading: false, errors: action.payload };
    }
    case EndpointActionTypes.SAVE_SUCCESS: {
      const index = state.data.findIndex((item) => item.id === action.payload.id);
      if (index === -1) {
        return {
          ...state,
          data: state.data.concat(action.payload),
        };
      }
      const updatedData = state.data.map((item, innerIndex) => {
        if (innerIndex !== index) {
          return item;
        }
        return {
          ...item,
          ...action.payload,
        };
      });
      return {
        ...state,
        data: updatedData,
      };
    }
    case EndpointActionTypes.DELETE_SUCCESS: {
      const index = state.data.findIndex((item) => item.id === action.payload.id);
      if (index === -1) {
        return state;
      }
      let dataCopy = [...state.data];
      dataCopy.splice(index, 1);
      return {
        ...state,
        data: dataCopy,
      };

    }
    default: {
      return state;
    }
  }
};

export { reducer as endpointReducer };
