import * as React from "react";
import { FormCheck, FormGroup, FormLabel, FormText } from "react-bootstrap";
import { WrappedFieldProps } from "redux-form";

interface IFormConfig {
  label: string;
  type?: string;
  explanation?: string;
  horizontal?: boolean;
}

class BootstrapCheckbox extends React.Component<IFormConfig & WrappedFieldProps> {
  public render() {
    const { label, explanation, input, horizontal } = this.props;
    return (
      <FormGroup className={horizontal ? "row" : ""}>
        {label && <FormLabel className={horizontal ? "col-sm-2" : ""}>{ label }</FormLabel>}
        <FormCheck className={horizontal ? "col-sm-10" : ""} {...input} />
        {explanation && <FormText className="text-muted">{explanation}</FormText>}
      </FormGroup>
    );
  }
}

export default BootstrapCheckbox;
