import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";

import { fetchError, fetchRequest, fetchSuccess } from "./actions";
import { SettingActionTypes } from "./types";
import { executeRestCallWithJsonAnswer, IJsonAnswer } from "../../utils/remoteUtils";

function* handleFetch(action: ReturnType<typeof fetchRequest>) {
  try {
    var token: string = yield select((state) => state.authentication.token);
    const res: IJsonAnswer = yield call(executeRestCallWithJsonAnswer, "get", "api/frontend/settings", token);
    if (res.error) {
      yield put(fetchError(res.error));
    } else {
      yield put(fetchSuccess(res.data));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(fetchError(err.stack!));
    } else {
      yield put(fetchError("An unknown error occured."));
    }
  }
}

function* watchRequests() {
  yield takeEvery(SettingActionTypes.FETCH_REQUEST, handleFetch);
}

export function* settingsSaga() {
  yield all([fork(watchRequests)]);
}
