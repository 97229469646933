import { action } from "typesafe-actions";
import { IAuthentication, IAuthenticationActionTypes, ILoginAttempt, ILogoutAttempt } from "./types";

export const login = (loginAttempt: ILoginAttempt) => action(IAuthenticationActionTypes.LOGIN_REQUEST, loginAttempt);
export const loginResult = (result: IAuthentication) => action(IAuthenticationActionTypes.LOGIN_RESULT, result);
export const loginError = (message: string) => action(IAuthenticationActionTypes.LOGIN_ERROR, message);

export const logout = (logoutAttempt: ILogoutAttempt) => action(IAuthenticationActionTypes.LOGOUT_REQUEST, logoutAttempt);
export const logoutSuccess = () => action(IAuthenticationActionTypes.LOGOUT_RESULT);
export const logoutError = (message: string) => action(IAuthenticationActionTypes.LOGOUT_ERROR, message);
