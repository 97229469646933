// these two imports are neccessary to support Internet Explorer!!
import "core-js/stable";
import "react-app-polyfill/ie9";

import * as React from "react";
import * as ReactDOM from "react-dom";
import { HashRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

/* Make the store available to all container
components in the application without passing it explicitly */
import { Provider } from "react-redux";

// Store type from Redux
import { Store } from "redux";

// Import and initialize internationalization
import "./i18n";

import ReduxToastr from "react-redux-toastr";
//import Cookies from "js-cookie";

// Import the store function and state
import configureStore from "./configureStore";

import "./index.css";
import BasePage from "./pages/BasePage";
import MessagePage from './pages/MessagePage';
import InstitutePage from './pages/InstitutePage';
import RulePage from './pages/RulePage';
import UserPage from "./pages/UserPage";
import EldaEndpointPage from './pages/EldaEndpointPage';
import FtpLogPage from './pages/FtpLogPage';
import NavigationBar from "./pages/NavigationBar";
import { IApplicationState } from "./store";
import PrivateRoute from "./PrivateRoute";
import LookupPage from "./pages/LookupPage";

interface IApplicationProperties {
  store: Store<IApplicationState>;
}

// Generate the store
const store = configureStore();

// Get the xgate security token and use it for future REST requests
//const queryToken = new URLSearchParams(window.location.search).get("token");
//if (queryToken) {
//  store.dispatch(verifyToken(queryToken));
//} else {
//  const cookieToken = Cookies.get("xgatetoken");
//  if (cookieToken) {
//    store.dispatch(verifyToken(cookieToken));
//  }
//}

/*
Create a root component that receives the store via props
and wraps the App component with Provider, giving props to containers
*/
const Root: React.FC<IApplicationProperties> = (props) => {
  return (
    <Provider store={props.store}>
      <Router>
        <NavigationBar/>
        <Switch>
          <Route exact={true} path="/" component={BasePage}/>
          <Route path="/index" component={BasePage}/>
          <PrivateRoute path="/messages" component={MessagePage}/>
          <PrivateRoute path="/ftplog" component={FtpLogPage}/>
          <PrivateRoute path="/lookup" component={LookupPage}/>
          <PrivateRoute path="/institutes" component={InstitutePage}/>
          <PrivateRoute path="/rules" component={RulePage}/>
          <PrivateRoute path="/users" component={UserPage}/>
          <PrivateRoute path="/endpoint" component={EldaEndpointPage}/>
          <Route path="*" exact={true} render ={
            /* this matches for all not handled urls */ 
            () => <Redirect to="/"/>
          }/>
        </Switch>
      </Router>
      <ReduxToastr
        timeOut={4000}
        position="bottom-right"
        transitionIn="bounceIn"
        transitionOut="bounceOut"
        newestOnTop
      />
    </Provider>
  );
};

// Render the App
ReactDOM.render(<Root store={store} />, document.getElementById("root") as HTMLElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
