export interface IUser {
  id: number;
  version: number;
  username: string;
  password: string;
  userFullname: string;
  permissions: string[];
  instituteFk: number;
  instituteName: string;
}

export interface IPasswordChangeRequest {
  userId: number;
  oldPassword: string;
  newPassword: string;
  newPasswordRepeat: string; // is transmit, but not used in the server. We use it for validation before transmission.
  force: boolean;
}

export enum UserActionTypes {
  FETCH_REQUEST = "@@users/FETCH_REQUEST",
  FETCH_SUCCESS = "@@users/FETCH_SUCCESS",
  FETCH_ERROR = "@@users/FETCH_ERROR",

  SAVE_REQUEST = "@@users/SAVE_REQUEST",
  SAVE_SUCCESS = "@@users/SAVE_SUCCESS",
  SAVE_ERROR = "@@users/SAVE_ERROR",

  PASSWORD_CHANGE_REQUEST = "@@users/PASSWORD_CHANGE_REQUEST",
  PASSWORD_CHANGE_SUCCESS = "@@users/PASSWORD_CHANGE_SUCCESS",
  PASSWORD_CHANGE_ERROR = "@@users/PASSWORD_CHANGE_ERROR",
}

export interface IUserState {
  readonly loading: boolean;
  readonly data: IUser[];
  readonly errors?: string;
}
