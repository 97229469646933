import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { Button, Table } from "react-bootstrap";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import * as notification from "../store/notifications";
import { IApplicationState } from "../store";
import { IUser, IPasswordChangeRequest } from "../store/user/types";
import * as userActions from "../store/user/actions";
import { IInstitute } from "../store/institute/types";
import * as instituteActions from "../store/institute/actions";
import { editIcon, keyIcon } from "../styles/icons";
import LoadingSpinner from "./LoadingSpinner";
import UserModalForm, { initUserModalFormAction } from "../form/UserModalForm";
import ChangePasswordForm, { initChangePasswordModalFormAction } from "../form/ChangePasswordForm";

interface IPropertiesFromState {
  data: IUser[];
  loading: boolean;
  errors?: string;
  institutes: IInstitute[];
}

interface IPropertiesFromDispatch {
  fetchRequest: typeof userActions.fetchRequest;
  saveRequest: typeof userActions.saveRequest;
  updatePasswordRequest: typeof userActions.passwordChangeRequest;
  initUserForm: typeof initUserModalFormAction;
  initPasswordForm: typeof initChangePasswordModalFormAction;
  newCredentialsNotMatching: typeof notification.newCredentialsNotMatchingNotification;
  fetchInstitutes: typeof instituteActions.fetchRequest;
}

interface IUserListState {
  createUser: boolean;
  showEditModal: boolean;
  showPasswordModal: boolean;
  lastSelectedUser: number;
}

class UserList extends React.Component<IPropertiesFromState & IPropertiesFromDispatch & WithTranslation, IUserListState> {
  public readonly state: IUserListState = {
    createUser: true,
    showEditModal: false,
    showPasswordModal: false,
    lastSelectedUser: 0,
  };

  public componentDidMount() {
    this.props.fetchRequest(true);
    this.props.fetchInstitutes(false);
  }

  public render() {
    const { data, loading, t, saveRequest, updatePasswordRequest, initUserForm: initInstituteForm, initPasswordForm: initInstitutePasswordForm, newCredentialsNotMatching } = this.props;

    const handleCloseModal = () => {
      this.setState({ showEditModal: false, showPasswordModal: false });
    };

    const handleCreate = () => {
      initInstituteForm({permissions: ["user"]});
      this.setState({ showEditModal: true, createUser: true, showPasswordModal: false, lastSelectedUser: 0 });
    };

    const handleEdit = (user: IUser) => {
      initInstituteForm(user);
      this.setState({ showEditModal: true, createUser: false, showPasswordModal: false, lastSelectedUser: user.id });
    };

    const submitChanges = (user: IUser) => {
      saveRequest(user);
      handleCloseModal();
    };

    const showPasswordChange = (user: IUser) => {
      initInstitutePasswordForm({});
      this.setState({ lastSelectedUser: user.id, showEditModal: false, showPasswordModal: true });
    }

    const changePassword = (passwordRequest: IPasswordChangeRequest) => {
      if (passwordRequest.newPassword !==  passwordRequest.newPasswordRepeat) {
        newCredentialsNotMatching();
        return;
      }
      passwordRequest = { ...passwordRequest, userId: this.state.lastSelectedUser};
      // i tried to set the userId in the init form method, but then everything went wrong. Every change I did, could not be written in the result object.
      updatePasswordRequest(passwordRequest);
      handleCloseModal();
    }

    if (loading) {
      return <LoadingSpinner i18nKey="progress.fetching" />;
    }
    if (!data || data.length === 0) {
      return <div>{t("pages.characters.noData")}</div>;
    }
    return (
      <div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>{t("pages.users.fullname")}</th>
              <th>{t("pages.users.username")}</th>
              <th>{t("pages.users.institute")}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {data.map((user) => {
              return (
                <tr key={user.id}>
                  <td>{user.userFullname}</td>
                  <td>{user.username}</td>
                  <td>{user.instituteName}</td>
                  <td>
                    <div>
                      <Button variant="link" onClick={() => handleEdit(user)} title={t("pages.users.editUser")}>
                        <FontAwesomeIcon icon={editIcon} pull="right"/>
                      </Button>
                      <Button variant="link" onClick={() => showPasswordChange(user)} title={t("pages.users.changePassword")}>
                        <FontAwesomeIcon icon={keyIcon} pull="right"/>
                      </Button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <Button variant="outline-info" onClick={handleCreate}>
          {t("pages.users.addUser")}
        </Button>
        <UserModalForm
          onSubmit={(submitChanges)}
          initialValues={{}}
          createUser={this.state.createUser}
          selectedUser={this.state.lastSelectedUser}
          institutes={this.props.institutes}
          show={this.state.showEditModal}
          onHide={handleCloseModal}
        />
        <ChangePasswordForm
          onSubmit={(changePassword)}
          initialValues={{}}
          show={this.state.showPasswordModal}
          onHide={handleCloseModal}
        />
      </div>
    ); 
  }
}

const mapStateToProps = (store: IApplicationState) => {
  return {
    data: store.user.data,
    loading: store.user.loading,
    errors: store.user.errors,
    institutes: store.institute.data,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchRequest: (includeDeleted: boolean) => dispatch(userActions.fetchRequest(includeDeleted)),
  saveRequest: (user: IUser) => dispatch(userActions.saveRequest(user)),
  updatePasswordRequest: (request: IPasswordChangeRequest) => dispatch(userActions.passwordChangeRequest(request)),
  initUserForm: (user: Partial<IUser>) => dispatch(initUserModalFormAction(user)),
  initPasswordForm: (request: Partial<IPasswordChangeRequest>) => dispatch(initChangePasswordModalFormAction(request)),
  newCredentialsNotMatching: () => dispatch(notification.newCredentialsNotMatchingNotification()),
  fetchInstitutes: (includeDeleted: boolean) => dispatch(instituteActions.fetchRequest(includeDeleted)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UserList));
