import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";

import * as notification from "../notifications";
import { fetchError, fetchRequest, fetchSuccess, retransmitRequest, retransmitSuccess, fetchMessageContent, fetchMessageContentSuccess, fetchMessageContentError, lookupRequest, lookupError, lookupResult } from "./actions";
import { MessageActionTypes } from "./types";
import { executeRestCall, executeRestCallWithJsonAnswer, IJsonAnswer } from "../../utils/remoteUtils";

function* handleFetch(action: ReturnType<typeof fetchRequest>) {
  try {
    //yield put(notification.fetchRequestNotification());
    var token: string = yield select((state) => state.authentication.token);
    const request = action.payload;
    var url = "";
    if (request.instituteId === 0) {
      url = "api/messages/elda";
    } else {
      url = `api/messages/institute/${request.instituteId}`;
    }
    url += `?pageSize=${request.pageSize}&page=${request.page}`;
    const res: IJsonAnswer = yield call(executeRestCallWithJsonAnswer, "get", url, token);
    //yield put(notification.cleanInfoMessages());
    if (res.error) {
      yield put(notification.restResultErrorNotification(res.statusCode, notification.fetchErrorNotification()));
      yield put(fetchError(res.error));
    } else {
      //yield put(notification.fetchSuccessNotification());
      yield put(fetchSuccess(res.data));
    }
  } catch (err) {
    yield put(notification.fetchErrorNotification());
    if (err instanceof Error) {
      yield put(fetchError(err.stack!));
    } else {
      yield put(fetchError("An unknown error occured."));
    }
  }
}

function* handleRetransmitRequest(action: ReturnType<typeof retransmitRequest>) {
  try {
    var token: string = yield select((state) => state.authentication.token);
    const res: IJsonAnswer = yield call(executeRestCallWithJsonAnswer, "post", `api/messages/retransmit/${action.payload.instituteId === 0 ? 'elda' : 'institute'}/${action.payload.message.id}?newFileNr=${action.payload.newFileNr}`, token);
    if (res.error) {
      if (action.payload.newFileNr) {
        yield put(notification.restResultErrorNotification(res.statusCode, notification.freshRetransmitErrorNotification()));
      } else {
        yield put(notification.restResultErrorNotification(res.statusCode, notification.retransmitErrorNotification()));
      }
    } else {
      if (action.payload.newFileNr) {
        yield put(notification.freshRetransmitSuccessNotification());
        yield put(fetchRequest({instituteId: action.payload.instituteId, page: 0, pageSize: 10}));
      } else {
        yield put(notification.retransmitSuccessNotification());
        yield put(retransmitSuccess(res.data));
      }
    }
  } catch (err) {
    yield put(notification.fetchErrorNotification());
    if (err instanceof Error) {
      yield put(fetchError(err.stack!));
    } else {
      yield put(fetchError("An unknown error occured."));
    }
  }
}

function* handleMessageContentRequest(action: ReturnType<typeof fetchMessageContent>) {
  try {
    var token: string = yield select((state) => state.authentication.token);
    const res: Response = yield call(executeRestCall, "get", `api/messages/${action.payload.instituteId === 0 ? 'elda' : 'institute'}/${action.payload.message.id}/content`, token);
    if (res.ok) {
      const content: string = yield res.text();
      yield put(notification.fetchSuccessNotification());
      yield put(fetchMessageContentSuccess(content));
    } else {
      yield put(notification.restResultErrorNotification(res.status, notification.fetchErrorNotification()));
      yield put(fetchMessageContentError(""));
    }
  } catch (err) {
    yield put(notification.fetchErrorNotification());
    if (err instanceof Error) {
      yield put(fetchMessageContentError(err.stack!));
    } else {
      yield put(fetchMessageContentError("An unknown error occured."));
    }
  }
}

function* handleMessageLookupRequest(action: ReturnType<typeof lookupRequest>) {
  try {
    yield put(notification.fetchRequestNotification());
    var token: string = yield select((state) => state.authentication.token);
    const request = action.payload;
    var url = `api/messages/lookup?mart=${request.mart}&eldaFileNr=${request.eldaFileNr}`;
    if (request.vsnr !== undefined) {
      url += `&vsnr=${request.vsnr}`;
    }
    const res: IJsonAnswer = yield call(executeRestCallWithJsonAnswer, "get", url, token);
    yield put(notification.cleanInfoMessages());
    if (res.statusCode !== 200) {
      yield put(notification.restResultErrorNotification(res.statusCode, notification.fetchErrorNotification()));
      if (res.statusCode === 464) {
        yield put(lookupError("notifications.serverResult.noLookupMatches.message"));
      } else if (res.statusCode === 465) {
        yield put(lookupError("notifications.serverResult.noLookupVpnrMatches.message"));
      } else {
        yield put(lookupError("notifications.fetch.error.message"));
      }
    } else {
      yield put(notification.fetchSuccessNotification());
      yield put(lookupResult(res.data));
    }
  } catch (err) {
    yield put(notification.fetchErrorNotification());
    if (err instanceof Error) {
      yield put(lookupError(err.stack!));
    } else {
      yield put(lookupError("An unknown error occured."));
    }
  }
}

function* watchRequests() {
  yield takeEvery(MessageActionTypes.FETCH_REQUEST, handleFetch);
  yield takeEvery(MessageActionTypes.RETRANSMIT_REQUEST, handleRetransmitRequest);
  yield takeEvery(MessageActionTypes.FETCH_CONTENT_REQUEST, handleMessageContentRequest);
  yield takeEvery(MessageActionTypes.LOOKUP_REQUEST, handleMessageLookupRequest);
}

export function* messageSaga() {
  yield all([fork(watchRequests)]);
}
