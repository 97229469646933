export interface IInstitute {
  id: number;
  version: number;
  name: string;
  communicationMode: string;
  noMessageModification: boolean;
  ftpUsername: string;
  ftpPassword: string;
  folderIncoming: string;
  folderOutgoing: string;
  filePrefix: string;
  filePostfix: string;
  moveBrokenOutgoing: boolean;
  seperateErrorLog: boolean;
}

export interface IInstitutePasswordChangeRequest {
  instituteId: number;
  password: string;
  passwordRepeat: string; // is transmit, but not used in the server. We use it for validation before transmission.
}

export enum InstituteActionTypes {
  FETCH_REQUEST = "@@institutes/FETCH_REQUEST",
  FETCH_SUCCESS = "@@institutes/FETCH_SUCCESS",
  FETCH_ERROR = "@@institutes/FETCH_ERROR",

  SAVE_REQUEST = "@@institutes/SAVE_REQUEST",
  SAVE_SUCCESS = "@@institutes/SAVE_SUCCESS",
  SAVE_ERROR = "@@institutes/SAVE_ERROR",

  PASSWORD_CHANGE_REQUEST = "@@institutes/PASSWORD_CHANGE_REQUEST",
  PASSWORD_CHANGE_SUCCESS = "@@institutes/PASSWORD_CHANGE_SUCCESS",
  PASSWORD_CHANGE_ERROR = "@@institutes/PASSWORD_CHANGE_ERROR",
}

export interface IInstituteState {
  readonly loading: boolean;
  readonly data: IInstitute[];
  readonly errors?: string;
}

export enum INSTITUTE_COMMUNICATION_MODE {
  FTP = "FTP",
  FILE = "FILE",
  NONE = "NONE",
}
