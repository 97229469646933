import * as React from "react";
import { Container } from "react-bootstrap";
import { withTranslation, WithTranslation } from "react-i18next";
import UserList from "../components/UserList";

const UserPage: React.FC<WithTranslation> = ({ t }) => {
  return (
    <Container fluid={true}>
      <h1>{t("pages.users.title")}</h1>
      <UserList />
    </Container>
  );
};

export default withTranslation()(UserPage);
