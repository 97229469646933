import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as notification from "../notifications";
import { login, loginError, loginResult, logout, logoutError, logoutSuccess } from "./actions";
import { IAuthenticationActionTypes } from "./types";
import { initLoginModalFormAction } from "../../form/LoginModalForm";
import { executeRestCallWithJsonAnswer, executeRestCall, IJsonAnswer } from "../../utils/remoteUtils";

function* handleLoginAttempt(action: ReturnType<typeof login>) {
  try {
    const res: IJsonAnswer = yield call(executeRestCallWithJsonAnswer, "post", `login`, "", action.payload);
    if (res.data.valid) {
      yield put(notification.loginSuccessNotification());
      yield put(initLoginModalFormAction({}));
    } else {
      yield put(notification.restResultErrorNotification(res.statusCode, notification.loginInvalidNotification()));
    }
    yield put(loginResult(res.data));
  } catch (err) {
    yield put(notification.loginErrorNotification());
    if (err instanceof Error) {
      yield put(loginError(err.stack!));
    } else {
      yield put(loginError("An unknown error occured."));
    }
  }
}

function* handleLogoutAttempt(action: ReturnType<typeof logout>) {
  try {
    const res: Response = yield call(executeRestCall, "post", `logout`, "", action.payload);
    if (res.ok) {
      yield put(notification.logoutSuccessNotification());
      yield put(initLoginModalFormAction({}));
    } else {
      yield put(notification.restResultErrorNotification(res.status, notification.logoutInvalidNotification()));
    }
    yield put(logoutSuccess());
  } catch (err) {
    yield put(notification.logoutErrorNotification());
    if (err instanceof Error) {
      yield put(logoutError(err.stack!));
    } else {
      yield put(logoutError("An unknown error occured."));
    }
  }
}

function* watchFetchRequest() {
  yield takeEvery(IAuthenticationActionTypes.LOGIN_REQUEST, handleLoginAttempt);
  yield takeEvery(IAuthenticationActionTypes.LOGOUT_REQUEST, handleLogoutAttempt);
}

export function* authenticationSaga() {
  yield all([fork(watchFetchRequest)]);
}
