import i18next from "i18next";
import { actions as toastrActions } from "react-redux-toastr";
import { Action } from "redux";

export const cleanInfoMessages = () => {
  return toastrActions.removeByType("info");
};

export const loginSuccessNotification = () => {
  return successNotification("login");
};

export const loginInvalidNotification = () => {
  return invalidNotification("login");
};

export const loginErrorNotification = () => {
  return errorNotification("login");
};

export const logoutSuccessNotification = () => {
  return successNotification("logout");
};

export const logoutInvalidNotification = () => {
  return invalidNotification("logout");
};

export const logoutErrorNotification = () => {
  return errorNotification("logout");
};

export const passwordChangeSuccessNotification = () => {
  return successNotification("passwordChange");
};

export const passwordChangeErrorNotification = () => {
  return errorNotification("passwordChange");
};

export const newCredentialsNotMatchingNotification = () => {
  return errorNotification("passwordChange", "notMatching");
};

export const retransmitSuccessNotification = () => {
  return successNotification("retransmitMessage");
};

export const retransmitErrorNotification = () => {
  return errorNotification("retransmitMessage");
};

export const freshRetransmitSuccessNotification = () => {
  return successNotification("freshRetransmitMessage");
};

export const freshRetransmitErrorNotification = () => {
  return errorNotification("freshRetransmitMessage");
};

export const instituteCreateSuccessNotification = () => {
  return successNotification("instituteUpdate", "createSuccess");
};

export const instituteUpdateSuccessNotification = () => {
  return successNotification("instituteUpdate", "updateSuccess");
};

export const instituteCreateFailureNotification = () => {
  return errorNotification("instituteUpdate", "createFailure");
};

export const instituteUpdateFailureNotification = () => {
  return errorNotification("instituteUpdate", "updateFailure");
};

export const eldaEndpointTestSuccess = () => {
  return successNotification('endpointTest', 'ok');
};

export const eldaEndpointTestError = (statusCode: number) => {
  if (statusCode === 490) {
    return errorNotification('endpointTest', 'connectionRefused');
  }
  if (statusCode === 491) {
    return errorNotification('endpointTest', 'connectionFailed');
  }
  if (statusCode === 492) {
    return errorNotification('endpointTest', 'eldaUnavailable');
  }
  if (statusCode === 493) {
    return errorNotification('endpointTest', 'wrongLoginData');
  }
  if (statusCode === 494) {
    return errorNotification('endpointTest', 'unexpectedAnswer');
  }
  if (statusCode === 495) {
    return errorNotification('endpointTest', 'eldaRetError');
  }
  if (statusCode === 496) {
    return errorNotification('endpointTest', 'quitFailed');
  }
  if (statusCode === 497) {
    return errorNotification('endpointTest', 'fileLoadError');
  }
  if (statusCode === 498) {
    return errorNotification('endpointTest', 'fileSendError');
  }
  return errorNotification('endpointTest', 'generic');
}

export const restResultErrorNotification = (statusCode: number, defaultAction: Action<any>) => {
  if (statusCode === 403) {
    return errorNotification('serverResult', 'forbidden');
  }
  if (statusCode === 404) {
    return errorNotification('serverResult', 'notFound');
  }
  if (statusCode === 409) {
    return errorNotification('serverResult', 'conflict');
  }
  if (statusCode === 460) {
    return errorNotification('serverResult', 'unknownInstitute');
  }
  if (statusCode === 461) {
    return errorNotification('serverResult', 'unknownUser');
  }
  if (statusCode === 462) {
    return errorNotification('serverResult', 'unknownEldaMessage');
  }
  if (statusCode === 463) {
    return errorNotification('serverResult', 'unknownInstituteMessage');
  }
  if (statusCode === 464) {
    return errorNotification('serverResult', 'noLookupMatches');
  }
  if (statusCode === 465) {
    return errorNotification('serverResult', 'noLookupVpnrMatches');
  }
  if (statusCode === 470) {
    return errorNotification('serverResult', 'wrongCurrentPassword');
  }
  if (statusCode === 471) {
    return errorNotification('serverResult', 'invalidNewPassword');
  }
  if (statusCode === 472) {
    return errorNotification('serverResult', 'operationUnsupported');
  }
  if (statusCode === 473) {
    return errorNotification('serverResult', 'instituteDoubleUsername');
  }
  if (statusCode === 474) {
    return errorNotification('serverResult', 'instituteDoubleName');
  }
  return defaultAction;
};

export const successNotification = (family: string, type = "success") => {
  return toastrActions.add({
    message: i18next.t(`notifications.${family}.${type}.message`),
    options: {
      removeOnHover: true,
      showCloseButton: true,
    },
    title: i18next.t(`notifications.${family}.${type}.title`),
    type: "success"
  });
};

export const invalidNotification = (family: string) => {
  return errorNotification(family, 'invalid');
};

export const errorNotification = (family: string, type = "error") => {
  return toastrActions.add({
    message: i18next.t(`notifications.${family}.${type}.message`),
    options: {
      removeOnHover: true,
      showCloseButton: true,
    },
    title: i18next.t(`notifications.${family}.${type}.title`),
    type: "error"
  });
};

export const fetchRequestNotification = () => {
  return toastrActions.add({
    message: i18next.t("notifications.fetch.request.message"),
    options: {
      removeOnHover: true,
      showCloseButton: false,
    },
    title: i18next.t("notifications.fetch.request.title"),
    type: "info",
  });
};

export const fetchSuccessNotification = () => {
  return toastrActions.add({
    message: i18next.t("notifications.fetch.success.message"),
    options: {
      removeOnHover: true,
      showCloseButton: true,
    },
    title: i18next.t("notifications.fetch.success.title"),
    type: "success",
  });
};

export const fetchErrorNotification = () => {
  return toastrActions.add({
    message: i18next.t("notifications.fetch.error.message"),
    options: {
      removeOnHover: true,
      showCloseButton: true,
    },
    title: i18next.t("notifications.fetch.error.title"),
    type: "error",
  });
};

export const deleteRequestNotification = () => {
  return toastrActions.add({
    message: i18next.t("notifications.delete.request.message"),
    options: {
      removeOnHover: true,
      showCloseButton: false,
    },
    title: i18next.t("notifications.delete.request.title"),
    type: "info",
  });
};

export const deleteSuccessNotification = () => {
  return toastrActions.add({
    message: i18next.t("notifications.delete.success.message"),
    options: {
      removeOnHover: true,
      showCloseButton: true,
    },
    title: i18next.t("notifications.delete.success.title"),
    type: "success",
  });
};

export const deleteErrorNotification = () => {
  return toastrActions.add({
    message: i18next.t("notifications.delete.error.message"),
    options: {
      removeOnHover: true,
      showCloseButton: true,
    },
    title: i18next.t("notifications.delete.error.title"),
    type: "error",
  });
};

export const saveRequestNotification = () => {
  return toastrActions.add({
    message: i18next.t("notifications.save.request.message"),
    options: {
      removeOnHover: true,
      showCloseButton: false,
    },
    title: i18next.t("notifications.save.request.title"),
    type: "info",
  });
};

export const saveSuccessNotification = () => {
  return toastrActions.add({
    message: i18next.t("notifications.save.success.message"),
    options: {
      removeOnHover: true,
      showCloseButton: true,
    },
    title: i18next.t("notifications.save.success.title"),
    type: "success",
  });
};

export const saveErrorNotification = () => {
  return toastrActions.add({
    message: i18next.t("notifications.save.error.message"),
    options: {
      removeOnHover: true,
      showCloseButton: true,
    },
    title: i18next.t("notifications.save.error.title"),
    type: "error",
  });
};

export const testEndpointRequestNotification = () => {
  return toastrActions.add({
    message: i18next.t("notifications.endpointTest.request.message"),
    options: {
      removeOnHover: true,
      showCloseButton: false,
      timeOut: 0,
    },
    title: i18next.t("notifications.endpointTest.request.title"),
    type: "info",
  });
};

export const validationErrorNotification = (key: string) => {
  return toastrActions.add({
    message: i18next.t(`notifications.validation.${key}`),
    options: {
      removeOnHover: true,
      showCloseButton: true,
    },
    title: i18next.t(`notifications.validation.title`),
    type: "error",
  });
};
