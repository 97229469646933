import { action } from "typesafe-actions";
import { IEldaEndpoint, EndpointActionTypes } from "./types";

export const fetchRequest = () => action(EndpointActionTypes.FETCH_REQUEST);
export const fetchSuccess = (data: IEldaEndpoint) => action(EndpointActionTypes.FETCH_SUCCESS, data);
export const fetchError = (message: string) => action(EndpointActionTypes.FETCH_ERROR, message);

export const saveRequest = (endpoint: IEldaEndpoint) => action(EndpointActionTypes.SAVE_REQUEST, endpoint);
export const saveSuccess = (endpoint: IEldaEndpoint) => action(EndpointActionTypes.SAVE_SUCCESS, endpoint);
export const saveError = (message: string) => action(EndpointActionTypes.SAVE_ERROR, message);

export const testRequest = (endpoint: Partial<IEldaEndpoint>) => action(EndpointActionTypes.TEST_REQUEST, endpoint);

export const deleteRequest = (endpoint: IEldaEndpoint) => action(EndpointActionTypes.DELETE_REQUEST, endpoint);
export const deleteSucess = (endpoint: IEldaEndpoint) => action(EndpointActionTypes.DELETE_SUCCESS, endpoint);
export const deleteError = (message: string) => action(EndpointActionTypes.DELETE_ERROR, message);
