import { Reducer } from "redux";
import { IAuthenticationActionTypes, IAuthenticationState } from "./types";

const initialState: IAuthenticationState = {
  errors: undefined,
  loggedIn: false,
  token: "",
  loading: false,
  authentication: undefined,
};

const reducer: Reducer<IAuthenticationState> = (state = initialState, action) => {
  switch (action.type) {
    case IAuthenticationActionTypes.LOGIN_REQUEST: {
      return { ...state, loading: true, authentication: undefined, errors: undefined, loggedIn: false, token: "" };
    }
    case IAuthenticationActionTypes.LOGIN_RESULT: {
      return { ...state, loading: false, authentication: action.payload, errors: undefined, loggedIn: action.payload.valid, token: action.payload.token };
    }
    case IAuthenticationActionTypes.LOGIN_ERROR: {
      return { ...state, loading: false, authentication: undefined, errors: action.payload, loggedIn: false, token: "" };
    }
    case IAuthenticationActionTypes.LOGOUT_RESULT: {
      return { ...state, loading: false, authentication: undefined, errors: action.payload, loggedIn: false, token: "" };
    }
    default: {
      return state;
    }
  }
};

export { reducer as authenticationReducer };
