import { action } from "typesafe-actions";
import { ISplitRuleSet, RuleActionTypes } from "./types";

export const fetchRequest = () => action(RuleActionTypes.FETCH_REQUEST);
export const fetchSuccess = (data: ISplitRuleSet) => action(RuleActionTypes.FETCH_SUCCESS, data);
export const fetchError = (message: string) => action(RuleActionTypes.FETCH_ERROR, message);

export const saveRequest = (data: ISplitRuleSet) => action(RuleActionTypes.SAVE_REQUEST, data);
export const saveSuccess = (result: ISplitRuleSet) => action(RuleActionTypes.SAVE_SUCCESS, result);
export const saveError = (message: string) => action(RuleActionTypes.SAVE_ERROR, message);

export const addRule = () => action(RuleActionTypes.ADD_RULE);
export const removeRule = (index: number) => action(RuleActionTypes.REMOVE_RULE, index);
export const moveRuleUp = (index: number) => action(RuleActionTypes.MOVE_RULE_UP, index);
export const moveRuleDown = (index: number) => action(RuleActionTypes.MOVE_RULE_DOWN, index);
