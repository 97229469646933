import * as React from "react";
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap";
import { withTranslation, WithTranslation } from "react-i18next";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { initialize } from "redux-form";
import { IUser } from "../store/user/types";
import { IInstitute } from "../store/institute/types";
import BootstrapFormGroup from "./BootstrapFormGroup";
import BootstrapSelectFormGroup from './BootstrapSelectFormGroup';

interface IUserModalFormProps {
  show: boolean;
  onHide: () => void;
  createUser: boolean;
  selectedUser: number;
  institutes: IInstitute[];
}

class UserModalForm extends React.Component<InjectedFormProps<IUser, IUserModalFormProps> & WithTranslation & IUserModalFormProps> {
  public render() {
    const { pristine, submitting, reset, handleSubmit, t, show, onHide, createUser, selectedUser, institutes } = this.props;
    return (
      <Modal show={show} onHide={onHide}>
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <ModalTitle>{t(createUser ? "pages.users.editor.create" : "pages.users.editor.edit")}</ModalTitle>
          </Modal.Header>
          <ModalBody>
            <Field
              name="userFullname"
              component={BootstrapFormGroup}
              label={t("pages.users.fullname")}
              placeholder={t("pages.users.editor.fullnamePlaceholder")}
            />
            <Field
              name="username"
              component={BootstrapFormGroup}
              label={t("pages.users.username")}
              placeholder={t("pages.users.editor.usernamePlaceholder")}
            />
            {createUser && <Field
              name="password"
              component={BootstrapFormGroup}
              label={t("pages.users.password")}
              placeholder={t("pages.users.editor.passwordPlaceholder")}
              type="password"
            />}
            {selectedUser !== 1 && <Field
              name="instituteFk"
              component={BootstrapSelectFormGroup}
              options={
                institutes && institutes.map((institute) => {
                  return { value: institute.id, label: institute.name };
                })
              }
              label={t("pages.users.institute")}
              placeholder={t("pages.users.editor.institutePlaceholder")}
            />}
          </ModalBody>
          <ModalFooter>
            <Button variant="secondary" type="button" disabled={pristine || submitting} onClick={reset}>
              {t("form.general.revert")}
            </Button>
            <Button variant="primary" type="submit" disabled={pristine || submitting}>
              {t(createUser ? "form.general.create" : "form.general.update")}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}

export const initUserModalFormAction = (institute: Partial<IUser>) => {
  return initialize("userFormModal", institute);
};

export default reduxForm<IUser, IUserModalFormProps>({
  form: "userFormModal",
})(withTranslation()(UserModalForm));
