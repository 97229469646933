import * as React from "react";
import { Nav, Row, Col, Container } from "react-bootstrap";
import { IAuthentication } from "../store/authentication";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { IApplicationState } from "../store";
import * as instituteActions from "../store/institute/actions";
import { IInstitute } from "../store/institute";
import { IFtpLogFetchRequest } from "../store/ftplog";
import * as ftplogActions from "../store/ftplog/actions";
import FtpLogList from "../components/FtpLogList";

interface IPropertiesFromState {
  authentication?: IAuthentication;
  institutes: IInstitute[];
}

interface IPropertiesFromDispatch {
  instituteFetchRequest: typeof instituteActions.fetchRequest;
  fetchFtpLogsRequest: typeof ftplogActions.fetchRequest;
}

interface IFtpLogPageState {
  selectedPage: string;
  instituteId: number;
}

class FtpLogPage extends React.Component<IPropertiesFromState & IPropertiesFromDispatch, IFtpLogPageState> {

  public readonly state: IFtpLogPageState = {
    selectedPage: "",
    instituteId: -1,
  }

  public componentDidMount() {
    if (this.props.authentication == null) {
      return;
    }
    // if authenticated user is the sysadmin => load institutes
    // otherwise: load the messages of the institute which belongs to the user
    if (this.props.authentication.sysadmin) {
      this.props.instituteFetchRequest(false);
    } else {
      this.setState({instituteId: this.props.authentication.instituteId});
      this.props.fetchFtpLogsRequest({instituteId: this.props.authentication.instituteId, page: 0, pageSize: 10});
    }
  }

  public render() {
    const { authentication, institutes, fetchFtpLogsRequest } = this.props;

    const selectElda = () => {
      this.setState({selectedPage: "elda", instituteId: 0});
      fetchFtpLogsRequest({instituteId: 0, page: 0, pageSize: 10});
    }
    const selectInstitute = (institute: IInstitute) => {
      this.setState({selectedPage: `institute-${institute.id}`, instituteId: institute.id});
      fetchFtpLogsRequest({instituteId: institute.id, page: 0, pageSize: 10});
    }

    return (
      <>
        {authentication !==  undefined && 
        <>
          {authentication.sysadmin && 
          <Container fluid={true} className="message-parent-box">
            <Row>
              <Col xs={2} className={"noPaddingRight"}>
                <Nav activeKey={this.state.selectedPage} className="flex-column nav-institute mh-100">
                  {authentication && authentication.sysadmin && <Nav.Link key="elda" eventKey="elda" onClick={() => selectElda()}>ELDA</Nav.Link>}
                  {institutes && institutes.map((institute) => {
                    return (<Nav.Link key={`institute-${institute.id}`} eventKey={`institute-${institute.id}`} onClick={() => selectInstitute(institute)}>{institute.name}</Nav.Link>);
                  })}
                </Nav>
              </Col>
              <Col xs={10} className={"noPaddingLeft noPaddingRight"}>
                <FtpLogList instituteId={this.state.instituteId} />
              </Col>
            </Row>
            </Container>
          }
          {!authentication.sysadmin && 
            <>
              <Container fluid={true}>
                <FtpLogList instituteId={this.state.instituteId} />
              </Container>
            </>
          }
        </>
        }
      </>
    );
  }
}

const mapStateToProps = (store: IApplicationState) => {
  return {
    authentication: store.authentication.authentication,
    institutes: store.institute.data,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  instituteFetchRequest: (includeDeleted: boolean) => dispatch(instituteActions.fetchRequest(includeDeleted)),
  fetchFtpLogsRequest: (request: IFtpLogFetchRequest) => dispatch(ftplogActions.fetchRequest(request)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FtpLogPage);
