import * as React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { IApplicationState } from "../store";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Collapse, DropdownItem, DropdownMenu, DropdownToggle, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, UncontrolledDropdown } from "reactstrap";
import NavDivider from "./NavDivider";
import { ILogoutAttempt, ILoginAttempt } from "../store/authentication";
import * as authActions from "../store/authentication/actions";
import { IPasswordChangeRequest } from "../store/user";
import * as userActions from "../store/user/actions";
import LoginModalForm, { initLoginModalFormAction } from "../form/LoginModalForm";
import ChangePasswordForm, { initChangePasswordModalFormAction } from "../form/ChangePasswordForm";
import * as notification from "../store/notifications";

interface ILinkProps {
  link: string;
  name: string;
};

const BootstrapNavLink: React.FC<ILinkProps> = ({ link, name }) => {
  return (
    <NavLink className="nav-link" to={link}>{name}</NavLink>
  );
};

interface IPropertiesFromState {
  loggedIn: boolean;
  isSysadmin?: boolean;
  token?: string;
  userId?: number;
}

interface IPropertiesFromDispatch {
  loginRequest: typeof authActions.login;
  logoutRequest: typeof authActions.logout;
  passwordChangeRequest: typeof userActions.passwordChangeRequest;
  newCredentialsNotMatchingNotification: typeof notification.newCredentialsNotMatchingNotification;
  initPasswordChangeModalRequest: typeof initChangePasswordModalFormAction;
}

interface INavigationBarState {
  showLoginModal: boolean;
  showPasswordChangeModal: boolean;
}

class NavigationBar extends React.Component<IPropertiesFromState & IPropertiesFromDispatch & WithTranslation, INavigationBarState> {

  public readonly state: INavigationBarState = {
    showLoginModal: false,
    showPasswordChangeModal: false,
  };

  public render() {
    const { t, i18n, loginRequest, logoutRequest, passwordChangeRequest, newCredentialsNotMatchingNotification, initPasswordChangeModalRequest, loggedIn, isSysadmin, userId } = this.props;

    const closeModals = () => {
      this.setState({ showLoginModal: false, showPasswordChangeModal: false });
    };

    const openLoginModal = () => {
      initLoginModalFormAction({});
      this.setState({ showLoginModal: true, showPasswordChangeModal: false })
    }

    const handleLoginAttempt = (loginAttempt: ILoginAttempt) => {
      loginRequest(loginAttempt);
      this.setState({ showLoginModal: false, showPasswordChangeModal: false });
    }

    const openPasswordChangeModal = () => {
      initPasswordChangeModalRequest({});
      this.setState({ showLoginModal: false, showPasswordChangeModal: true })
    }

    const handlePasswordChangeAttempt = (request: IPasswordChangeRequest) => {
      // inject the correct user id
      if (request.newPassword !== request.newPasswordRepeat) {
        newCredentialsNotMatchingNotification();
        return;
      }
      request = {...request, userId: userId != null ? userId : 0, force: false }
      passwordChangeRequest(request);
      this.setState({ showLoginModal: false, showPasswordChangeModal: false });
    }

    return (
      <>
        <Navbar color="dark" dark expand="md">
          <NavbarBrand href="#/">XGate</NavbarBrand>
          <NavbarToggler />
          <Collapse isOpen={true} className="justify-content-end" navbar>
            <Nav className="ml-auto" navbar>
              {!loggedIn && <NavItem>
                <NavLink className="nav-link" to="#" onClick={(e) => openLoginModal()}>
                  {t("menu.login")}
                </NavLink>
              </NavItem>}
              {loggedIn && <NavItem>
                <NavLink className="nav-link" to="/index" onClick={(e) => {openPasswordChangeModal(); e.preventDefault(); }}>
                  {t("menu.changePassword")}
                </NavLink>
              </NavItem>}
              {loggedIn && <NavItem>
                <NavLink className="nav-link" to="/index" onClick={() => logoutRequest({token: this.props.token !== undefined ? this.props!.token : ""})}>
                  {t("menu.logout")}
                </NavLink>
              </NavItem>}
              {loggedIn && <NavDivider/>}
              {loggedIn && <NavItem>
                <BootstrapNavLink link="/messages" name={t("menu.messages")} />
              </NavItem>}
              {loggedIn && <NavItem>
                <BootstrapNavLink link="/ftplog" name={t("menu.ftplog")} />
              </NavItem>}
              {loggedIn && <NavItem>
                <BootstrapNavLink link="/lookup" name={t("menu.lookup")} />
              </NavItem>}
              {isSysadmin && <NavItem>
                <BootstrapNavLink link="/institutes" name={t("menu.institutes")} />
              </NavItem>}
              {isSysadmin && <NavItem>
                <BootstrapNavLink link="/rules" name={t("menu.rules")} />
              </NavItem>}
              {isSysadmin && <NavItem>
                <BootstrapNavLink link="/users" name={t("menu.users")} />
              </NavItem>}
              {isSysadmin && <NavItem>
                <BootstrapNavLink link="/endpoint" name={t("menu.endpoint")} />
              </NavItem>}
              <NavDivider/>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  {t("menu.language")}
                </DropdownToggle>
                <DropdownMenu end>
                  <DropdownItem onClick={(event) => i18n.changeLanguage("en")}>
                    {t("menu.languages.english")}
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={(event) => i18n.changeLanguage("de")}>
                    {t("menu.languages.german")}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Navbar>
        <LoginModalForm
          onSubmit={handleLoginAttempt}
          initialValues={{}}
          show={this.state.showLoginModal}
          onHide={closeModals}
        />
        <ChangePasswordForm
          onSubmit={handlePasswordChangeAttempt}
          initialValues={{}}
          show={this.state.showPasswordChangeModal}
          onHide={closeModals}
        />
      </>
    );
  }
}

const mapStateToProps = (store: IApplicationState) => {
  return {
    loggedIn: store.authentication.loggedIn,
    isSysadmin: store.authentication.authentication?.sysadmin,
    token: store.authentication.authentication?.token,
    userId: store.authentication.authentication?.userId,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loginRequest: (loginAttempt: ILoginAttempt) => dispatch(authActions.login(loginAttempt)),
  logoutRequest: (logoutAttempt: ILogoutAttempt) => dispatch(authActions.logout(logoutAttempt)),
  passwordChangeRequest: (passwordChangeRequest: IPasswordChangeRequest) => dispatch(userActions.passwordChangeRequest(passwordChangeRequest)),
  newCredentialsNotMatchingNotification: () => dispatch(notification.newCredentialsNotMatchingNotification()),
  initPasswordChangeModalRequest: (initValue: Partial<IPasswordChangeRequest>) => dispatch(initChangePasswordModalFormAction(initValue)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(NavigationBar));
