import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { IApplicationState } from "../store";

interface IBasePageParams {
  loggedIn: boolean;
  userFullName?: string; 
}

const BasePage: React.FC<IBasePageParams & WithTranslation> = ({t, loggedIn, userFullName}) => {
  return (
    <>
      <h1>{t("pages.main.welcome")}</h1>
      {loggedIn && <h4>{t("pages.main.welcomeSubAuthenticated", {userFullName: userFullName})}</h4>}
      {!loggedIn && <h4>{t("pages.main.welcomeSubUnauthenticated")}</h4>}
    </>
  );
};

const mapStateToProps = (store: IApplicationState) =>  {
  return {
    loggedIn: store.authentication.loggedIn,
    userFullName: store.authentication.authentication?.userFullName,
  };
};

export default connect(mapStateToProps) (withTranslation()(BasePage));
