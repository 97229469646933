export enum MESSAGE_DIRECTION {
  ELDA = "ELDA",
  INSTITUTE = "INSTITUTE",
};

export enum MESSAGE_ORIGIN {
  INSTITUTE = "INSTITUTE",
  ELDA = "ELDA",
};

/**
 * The status a message can have in the message lifecycle.
 *
 * WAIT_FOR_HANDLING -> Message waits to be handled by one of the two middleware services
 * WAIT_FOR_TRANSMISSION -> Message waits to be transmit either to ELDA or to an institute, depending of the direction
 * TRANSMIT -> Message was transmit and therefore is finished on its level
 * ERROR -> Message transmission has failed
 * PENDING -> Message was prepared but not yet released for the next step in the lifecycle
 * DELETED -> Message was manually deleted before the next step in the lifecycle could be applied
 */
export enum TRANSMISSION_STATUS {
  WAIT_FOR_HANDLING = "WAIT_FOR_HANDLING",
  WAIT_FOR_TRANSMISSION = "WAIT_FOR_TRANSMISSION",
  TRANSMIT = "TRANSMIT",
  ERROR = "ERROR",
  PENDING = "PENDING",
  DELETED = "DELETED", // should never come here ...
};
