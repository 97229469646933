import { faPencilAlt, faPlusCircle, faTrashAlt, faRedo, faKey, faArrowUp, faArrowDown, faFolderOpen } from "@fortawesome/free-solid-svg-icons";

export const plusIcon = faPlusCircle;
export const editIcon = faPencilAlt;
export const trashIcon = faTrashAlt;
export const redoIcon = faRedo;
export const keyIcon = faKey;
export const arrowUp = faArrowUp;
export const arrowDown = faArrowDown;
export const openIcon = faFolderOpen;
