import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      base: {
        bool: {
          true: 'True',
          false: 'False',
        },
      },
      menu: {
        language: 'Language',
        languages: {
          english: 'English',
          german: 'German',
        },
        login: 'Login',
        logout: 'Logout',
        changePassword: 'Change Password',
        messages: 'Messages',
        institutes: 'Institutes',
        users: 'Users',
        endpoint: 'ELDA',
        rules: 'Split-Rules',
        ftplog: 'FTP-Log',
        lookup: 'Lookup',
      },
      form: {
        general: {
          create: 'Create',
          login: 'Login',
          revert: 'Revert',
          update: 'Update',
          changePassword: 'Change password',
          search: 'Search',
        },
      },
      pages: {
        main: {
          welcome: 'Welcome to XGate!',
          welcomeSubUnauthenticated: 'You have to log in before you can perform any actions',
          welcomeSubAuthenticated: 'Hallo {{userFullName}}. Please select the area in which you want to work.'
        },
        login: {
          title: 'XGate Login',
          username: 'Username',
          password: 'Password',
          editor: {
            userNamePlaceholder: 'Enter username',
            passwordPlaceholder: 'Enter password',
          }
        },
        passwordChange: {
          title: 'Change Password',
          oldPassword: 'Current password',
          newPassword: 'Desired password',
          newPasswordRepeat: 'Repeat desired password',
          editor: {
            oldPasswordPlaceholder: 'Enter the current password',
            newPasswordPlaceholder: 'Enter the desirded password',
            newPasswordRepeatPlaceholder: 'Re-enter the desired password',
          },
        },
        messages: {
          noData: 'No messages available',
          selectOrigin: 'Please select a data source',
          loadError: 'Messages could not be loaded!',
          date: 'Date',
          mart: 'MART',
          fileName: 'File Name',
          fileNr: 'FileNr',
          fileNrInstitute: 'FileNr-Institute',
          fileNrElda: 'FileNr-ELDA',
          status: 'Status',
          direction: 'Receipient',
          directionEnum: {
            ELDA: 'ELDA',
            INSTITUTE: 'Institute',
          },
          traeger: 'Cost Unit',
          institute: 'Institute',
          statusEnum: {
            TRANSMIT: 'Transmit',
            WAIT_FOR_HANDLING: 'Wait for handling',
            WAIT_FOR_TRANSMISSION: 'Wait for transmission',
            WAIT_FOR_LOADING: 'Wait until loaded',
            WAIT_FOR_MERGE: 'Wait for transmission window',
            ERROR: 'Error',
            PENDING: 'Wait for transmission',
            DELETED: 'Deleted',
          },
          retransmit: 'Transmit same message once again',
          retransmitFresh: 'Transmit message as new',
          showContent: 'Show message content',
          contentError: 'Error loading message content',
          lookup: {
            mart: 'Meldungsart',
            martPlaceholder: 'Select meldungsart',
            fileNrEldaPlaceholder: 'Enter FileNr ELDA',
            vsnr: 'Social Security Number',
            vsnrPlaceholder: 'Enter optional social security number',
          },
        },
        institutes: {
          active: 'Active',
          addInstitute: 'Add institute',
          changePassword: 'Edit FTP-Password',
          communicationMode: 'Communication Mode',
          communicationModes: {
            FTP: 'Ftp',
            FILE: 'Files',
            NONE: 'Inactive'
          },
          editor: {
            create: 'Create Institute',
            edit: 'Edit Institute',
            namePlaceholder: 'Enter institute name',
            usernamePlaceholder: 'Enter FTP-Username',
            passwordPlaceholder: 'Enter FTP-Password',
          },
          editInstitute: 'Edit institute',
          folderIncoming: 'Folder for incoming messages (absolute)',
          folderOutgoing: 'Folder for outgoing messages (absolute)',
          filePrefix: 'File Prefix',
          filePostfix: 'File Postfix',
          ftpPassword: 'FTP-Password',
          ftpUsername: 'FTP-Username',
          moveBrokenOutgoing: 'Move invalid outgoing files to broken subfolder',
          name: 'Name',
          noData: 'No institutes available',
          noMessageModification: 'Do not change XEdikur files',
          seperateErrorLog: 'One error log per file',
          title: 'Institutes',
        },
        users: {
          title: 'Users',
          fullname: 'Full-Name', 
          username: 'Username',
          password: 'Password',
          institute: 'Institute',
          addUser: 'Add user',
          editUser: 'Edit user',
          changePassword: 'Change password',
          editor: {
            create: 'Create User',
            edit: 'Edit User',
            fullnamePlaceholder: 'Enter full name',
            usernamePlaceholder: 'Enter username',
            passwordPlaceholder: 'Enter password',
            institutePlaceholder: 'Enter institute',
          },
        },
        endpoint: {
          title: 'ELDA-Connection Configuration',
          url: 'URL',
          urlPlaceholder: 'Enter URL',
          port: 'Port',
          portPlaceholder: 'Enter port',
          username: 'Elda-Username',
          usernamePlaceholder: 'Enter Elda-Benutzername',
          password: 'Elda-Password',
          passwordPlaceholder: 'Enter Elda-Password',
          ftps: 'FTPS active',
          test: 'Test connection',
          noData: 'No endpoints defined!',
          endpointMode: 'Mode',
          endpointModes: {
            INACTIVE: 'Inactive',
            SEND: 'Send',
            RECEIVE: 'Receive',
            FULL: 'Send & Receive',
          },
          createEndpoint: 'Create Endpoint',
          editEndpoint: 'Edit Endpoint',
          deleteEndpoint: 'Delete Endpoint',
        },
        rules: {
          title: 'Config message split rules',
          criteria: 'Criteria',
          criteriaPlaceholder: 'Select criteria',
          value: 'Value',
          institute: 'Institute',
          institutePlaceholder: 'Select institute',
          default: 'Default',
          addRule: 'Add rule',
          removeRule: 'Remove rule',
          moveRuleUp: 'Move rule up',
          moveRuleDown: 'Move rule down',
          categories: {
            tarifkategorie: 'Tarifkategorie'
          },
        },
        ftpLog: {
          loadError: 'Error while loading the log entries',
          selectOrigin: 'Please select a data source',
          noData: 'Found no log entries',
          connectTime: 'Timestamp',
          success: 'Success',
          eldaRetContent: 'elda.ret',
          downloads: 'Downloads',
          uploads: 'Uploads',
          notRequested: 'Not requested',
          showLog: 'Show complete log',
          modalError: 'Fehler beim Laden des Log-Eintrags!',
        },
      },
      progress: {
        fetching: 'Loading data ...',
      },
      notifications: {
        login: {
          error: {
            title: 'Error',
            message: 'Unable to perform login action!',
          },
          invalid: {
            title: 'Login failed',
            message: 'Login with given credentials failed!',
          },
          success: {
            title: 'Login success',
            message: 'Login successfully.'
          },
        },
        logout: {
          error: {
            title: 'Error',
            message: 'Unable to perform logout action!',
          },
          success: {
            title: 'Logout success',
            message: 'Logout successfully.'
          },
        },
        passwordChange: {
          error: {
            title: 'Error',
            message: 'Unable to change your password!',
          },
          success: {
            title: 'Password changed',
            message: 'Password has successfully been changed.',
          },
          notMatching: {
            title: 'Validation error',
            message: 'New password and its reentered variant are not identical!',
          }
        },
        retransmitMessage: {
          error: {
            title: 'Retransmit error',
            message: 'The message could not be marked for re-transmission.',
          },
          success: {
            title: 'Message retransmission',
            message: 'The message is marked for re-transmission.',
          },
        },
        freshRetransmitMessage: {
          error: {
            title: 'Retransmit error',
            message: 'The message could not be created as new.',
          },
          success: {
            title: 'Message retransmission',
            message: 'The message will be sent with a new file number.',
          },
        },
        instituteUpdate: {
          createSuccess: {
            title: 'Creation successful',
            message: 'Institute successfully created.',
          },
          createFailure: {
            title: 'Creation failed',
            message: 'Institute could not be created!',
          },
          updateSuccess: {
            title: 'Update successful',
            message: 'Institute successfully updated.',
          },
          updateFailure: {
            title: 'Update failed',
            message: 'Institute could not be updated!',
          },
        },
        serverResult: {
          forbidden: {
            title: 'Access restricted',
            message: 'The selected operation is not permitted to you!',
          },
          notFound: {
            title: 'Not found',
            message: 'The selected resource could not be found!',
          },
          conflict: {
            title: 'Conflict',
            message: 'The selected resource was changed in the mean time. Your change can not be performed!',
          },
          unknownInstitute: {
            title: 'Unknown Institute',
            message: 'The selected institute is unknown to the system!',
          },
          unknownUser: {
            title: 'Unknown User',
            message: 'The selected user is unknown to the system!',
          },
          unknownEldaMessage: {
            title: 'Unknown ELDA Message',
            message: 'The selected ELDA message is unknown to the system!',
          },
          unknownInstituteMessage: {
            title: 'Unknown Institute Message',
            message: 'The selected institute message is unknown to the system!',
          },
          wrongCurrentPassword: {
            title: 'Password-Update Failed',
            message: 'The given current password is not correct!',
          },
          invalidNewPassword: {
            title: 'Password-Update Failed',
            message: 'The given new password is invalid!',
          },
          operationUnsupported: {
            title: 'Operation Failed',
            message: 'The selected operation is not supported!',
          },
          doubleUsername: {
            title: 'Update Failed',
            message: 'The given username is already taken!',
          },
          noLookupMatches: {
            title: 'Nothing Found',
            message: 'Found no messages for the given criteria!',
          },
          noLookupVpnrMatches: {
            title: 'Non Unique Result',
            messages: 'Found no unique result for the given criteria! Try to set the social security number!',
          },
          instituteDoubleUsername: {
            title: 'Save Failed',
            message: 'This FTP username is already taken!',
          },
          instituteDoubleName: {
            title: 'Save Failed',
            message: 'This institute name is already taken!',
          },
        },
        endpointTest: {
          request: {
            title: 'Testing Connection',
            message: 'Testing the connection to the given endpoint ..'
          },
          ok: {
            title: 'Connection Successful',
            message: 'The connection is configured properly.',
          },
          generic: {
            title: 'Connection Failed',
            message: 'An unexpected error occured!',
          },
          connectionRefused: {
            title: 'Connection Failed',
            message: 'Server refused the connection!',
          },
          connectionFailed: {
            title: 'Connection Failed',
            message: 'Connection to the server could not be established!',
          },
          eldaUnavailable: {
            title: 'Connection Failed',
            message: 'The Elda server is currently unavailable!',
          },
          wrongLoginData: {
            title: 'Connection Failed',
            message: 'Username and/or password is incorrect!',
          },
          unexpectedAnswer: {
            title: 'Connection Failed',
            message: 'Elda server did not respond with a defined message!',
          },
          eldaRetError: {
            title: 'Elda Server Error',
            message: 'The elda.ret file could not be loaded!',
          },
          quitFailed: {
            title: 'Elda Server Error',
            message: 'The connection to the elda server could not be closed!',
          },
          fileLoadError: {
            title: 'Elda Problem',
            message: 'Error during retrieving of a file!',
          },
          fileSendError: {
            title: 'Elda Problem',
            message: 'Error during transmission of a file!',
          },
        },
        fetch: {
          request: {
            title: 'Fetching ...',
            message: 'Loading data from server ...',
          },
          success: {
            title: 'Loading Success',
            message: 'The data fetch was successful',
          },
          error: {
            title: 'Loading Error',
            message: 'Unable to load data from server!',
          },
        },
        delete: {
          request: {
            title: 'Deleting ...',
            message: 'Deleting data from server ...',
          },
          success: {
            title: 'Delete Success',
            message: 'The data deletion was successful',
          },
          error: {
            title: 'Delete Error',
            message: 'Unable to delete data from server!',
          },
        },
        save: {
          request: {
            title: 'Saving ...',
            message: 'Saving data at server ...',
          },
          success: {
            title: 'Save Success',
            message: 'The data saving was successful.',
          },
          error: {
            title: 'Save Error',
            message: 'Unable to save data at the server!',
          },
        },
        validation: {
          title: 'Validation Error',
          lookup: {
            missingMart: 'Meldungsart is required!',
            missingFileNr: 'File Nr is required!',
          },
        },
      },
    },
  },
  de: {
    translation: {
      base: {
        bool: {
          true: 'Ja',
          false: 'Nein',
        },
      },
      menu: {
        language: 'Sprache',
        languages: {
          english: 'Englisch',
          german: 'Deutsch',
        },
        login: 'Anmelden',
        logout: 'Abmelden',
        changePassword: 'Passwort ändern',
        messages: 'Nachrichten',
        institutes: 'Institute',
        users: 'Benutzer',
        endpoint: 'ELDA',
        rules: 'Verteilung',
        ftplog: 'FTP-Log',
        lookup: 'Suche',
      },
      form: {
        general: {
          create: 'Erstellen',
          login: 'Login',
          revert: 'Zurücksetzen',
          update: 'Aktualisieren',
          changePassword: 'Passwort aktualisieren',
          search: 'Suchen',
        },
      },
      pages: {
        main: {
          welcome: 'Willkommen bei XGate!',
          welcomeSubUnauthenticated: 'Sie müssen sich erst einloggen um arbeiten zu können.',
          welcomeSubAuthenticated: 'Hallo {{userFullName}}. Bitte wählen Sie den Bereich in dem Sie arbeiten wollen.'
        },
        login: {
          title: 'XGate Login',
          username: 'Benutzername',
          password: 'Passwort',
          editor: {
            userNamePlaceholder: 'Benutzername eingeben',
            passwordPlaceholder: 'Passwort eingeben',
          }
        },
        passwordChange: {
          title: 'Passwort ändern',
          oldPassword: 'Aktuelles Passwort',
          newPassword: 'Gewünschtes Passwort',
          newPasswordRepeat: 'Gewünschtes Passwort Wiederholung',
          editor: {
            oldPasswordPlaceholder: 'Aktuelles Passwort eingeben',
            newPasswordPlaceholder: 'Gewünschtes Passwort eingeben',
            newPasswordRepeatPlaceholder: 'Gewünschtes Passwort wiederholen'
          },
        },
        messages: {
          noData: 'Keine Nachrichten vorhanden',
          selectOrigin: 'Bitte Datenquelle wählen',
          loadError: 'Nachrichten konnten nicht geladen werden!',
          date: 'Datum',
          mart: 'MART',
          fileName: 'Dateiname',
          fileNr: 'FileNr',
          fileNrInstitute: 'FileNr-Institut',
          fileNrElda: 'FileNr-ELDA',
          status: 'Status',
          direction: 'Addressat',
          directionEnum: {
            ELDA: 'ELDA',
            INSTITUTE: 'Institut',
          },
          traeger: 'Träger',
          institute: 'Institut',
          statusEnum: {
            TRANSMIT: 'Übertragen',
            WAIT_FOR_HANDLING: 'Warte auf Bearbeitung',
            WAIT_FOR_TRANSMISSION: 'Warte auf Übertragung',
            WAIT_FOR_LOADING: 'Warte auf Abholung',
            WAIT_FOR_MERGE: 'Warte auf Sendefenster',
            ERROR: 'Fehler',
            PENDING: 'Warte auf Übertragung',
            DELETED: 'Gelöscht',
          },
          retransmit: 'Die gleiche Nachricht noch einmal übertragen',
          retransmitFresh: 'Die Nachricht mit neuer File Nummer übertragen',
          showContent: 'Nachricht anzeigen',
          contentError: 'Fehler beim Laden der Nachricht',
          lookup: {
            mart: 'Meldungsart',
            martPlaceholder: 'Meldungsart wählen',
            fileNrEldaPlaceholder: 'FileNr ELDA angeben',
            vsnr: 'Versicherungsnummer Patient',
            vsnrPlaceholder: 'Versicherungsnummer angeben',
          },
        },
        institutes: {
          active: 'Aktiv',
          addInstitute: 'Institut hinzufügen',
          communicationMode: 'Übertragungsmodus',
          communicationModes: {
            FTP: 'Ftp',
            FILE: 'Dateien',
            NONE: 'Inaktiv'
          },
          changePassword: 'FTP-Passwort ändern',
          editor: {
            create: 'Institut erstellen',
            edit: 'Institut bearbeiten',
            namePlaceholder: 'Institutnamen eingeben',
            usernamePlaceholder: 'FTP-Benutzername eingeben',
            passwordPlaceholder: 'FTP-Passwort eingeben',
          },
          editInstitute: 'Institut bearbeiten',
          folderIncoming: 'Ordner für einkommende Nachrichten (absolut)',
          folderOutgoing: 'Ordner für ausgehende Nachrichten (absolut)',
          filePrefix: 'Prefix für übermittelte Dateien',
          filePostfix: 'Postfix für übermittelte Dateien',
          ftpPassword: 'FTP-Passwort',
          ftpUsername: 'FTP-Benutername',
          moveBrokenOutgoing: 'Verschiebt fehlerhafte ausgehende Nachrichten in Unterordner',
          name: 'Name',
          noData: 'Keine Institute vorhanden',
          noMessageModification: 'Dateien unverändert lassen',
          seperateErrorLog: 'Eigenes Logfile pro Datei',
          title: 'Institute',
        },
        users: {
          title: 'Benutzer',
          fullname: 'Bezeichnung', 
          username: 'Benutzername',
          password: 'Passwort',
          institute: 'Institut',
          addUser: 'Benutzer hinzufügen',
          editUser: 'Benutzer bearbeiten',
          changePassword: 'Passwort ändern',
          editor: {
            create: 'Benutzer erstellen',
            edit: 'Benutzer bearbeiten',
            fullnamePlaceholder: 'Bezeichnung eingeben',
            usernamePlaceholder: 'Benutzername eingeben',
            passwordPlaceholder: 'Passwort eingeben',
            institutePlaceholder: 'Institut eingeben',
          },
        },
        endpoint: {
          title: 'ELDA Verbindungsdaten Konfiguration',
          url: 'URL',
          urlPlaceholder: 'URL eingeben',
          port: 'Port',
          portPlaceholder: 'Port angeben',
          username: 'Elda-Benutzername',
          usernamePlaceholder: 'Elda-Benutzername angeben',
          password: 'Elda-Passwort',
          passwordPlaceholder: 'Elda-Passwort angeben',
          ftps: 'FTPS Aktiv',
          test: 'Verbindung testen',
          noData: 'Keine Endpunkte definiert!',
          endpointMode: 'Modus',
          endpointModes: {
            INACTIVE: 'Inaktiv',
            SEND: 'Senden',
            RECEIVE: 'Empfangen',
            FULL: 'Senden und Empfangen',
          },
          createEndpoint: 'Endpunkt erstellen',
          editEndpoint: 'Endpunkt bearbeiten',
          deleteEndpoint: 'Endpunkt löschen',
        },
        rules: {
          title: 'Verteilerregeln definieren',
          criteria: 'Kriterium',
          criteriaPlaceholder: 'Kriterium wählen',
          value: 'Vergleichswert',
          institute: 'Institut',
          institutePlaceholder: 'Institut wählen',
          default: 'Standard',
          addRule: 'Regel hinzufügen',
          removeRule: 'Regel entfernen',
          moveRuleUp: 'Regel nach oben verschieben',
          moveRuleDown: 'Regel nach oben verschieben',
          categories: {
            tarifkategorie: 'Tarifkategorie'
          },
        },
        ftpLog: {
          loadError: 'Fehler beim Laden der Log Einträge',
          selectOrigin: 'Bitte Datenquelle wählen',
          noData: 'Keine Log Einträge gefunden',
          connectTime: 'Zeitpunkt',
          success: 'Erfolg',
          eldaRetContent: 'elda.ret',
          downloads: 'Downloads',
          uploads: 'Uploads',
          notRequested: 'Nicht abgefragt',
          showLog: 'Zeige kompletten Log Eintrag',
          modalError: 'Fehler beim Laden des Log-Eintrags!',
        },
      },
      progress: {
        fetching: 'Lade Daten ...',
      },
      notifications: {
        login: {
          error: {
            title: 'Fehler',
            message: 'Fehler bei der Kommunikation mit dem Server!',
          },
          invalid: {
            title: 'Login fehlgeschlagen',
            message: 'Anmeldung mit den gegebenen Login Daten war nicht erfolgreich!',
          },
          success: {
            title: 'Login erfolgreich',
            message: 'Erfolgreich angemeldet.'
          },
        },
        logout: {
          error: {
            title: 'Fehler',
            message: 'Fehler bei der Kommunikation mit dem Server!',
          },
          success: {
            title: 'Logout erfolgreich',
            message: 'Erfolgreich abgemeldet.'
          },
        },
        passwordChange: {
          error: {
            title: 'Fehler',
            message: 'Fehler beim Ändern des Passworts!',
          },
          success: {
            title: 'Password aktualisiert',
            message: 'Das Passwort wurde erfolgreich geändert.',
          },
          notMatching: {
            title: 'Validierungsfehler',
            message: 'Neues Passwort und die erneute Eingabe des Passworts stimmen nicht überein!',
          }
        },
        retransmitMessage: {
          error: {
            title: 'Zurückstellung fehlgeschlagen',
            message: 'Die Nachricht konnte nicht für eine erneute Übertragung markiert werden.',
          },
          success: {
            title: 'Nachricht zurückgestellt',
            message: 'Nachricht wird bei der nächsten Abfrage erneut übertragen.',
          },
        },
        freshRetransmitMessage: {
          error: {
            title: 'Neue Zustellung fehlgeschlagen',
            message: 'Die Nachricht konnte nicht für eine erneute Übertragung erstellt werden.',
          },
          success: {
            title: 'Neue Zustellung vorbereitet',
            message: 'Nachricht wurde mit neuer File Nummer erstellt.',
          },
        },
        instituteUpdate: {
          createSuccess: {
            title: 'Erstellen erfolgreich',
            message: 'Institut wurde erflogreich angelegt.',
          },
          createFailure: {
            title: 'Erstellen fehlgeschlagen',
            message: 'Institut konnte nicht angelegt werden!',
          },
          updateSuccess: {
            title: 'Aktualisierung erfolgreich',
            message: 'Institut wurde erfolgreich aktualisiert.',
          },
          updateFailure: {
            title: 'Aktualisierung fehlgeschlagen',
            message: 'Institut konnte nicht aktualisiert werden!',
          },
        },
        serverResult: {
          forbidden: {
            title: 'Zugriff verboten',
            message: 'Die gewählte Operation ist für Sie nicht gestattet!',
          },
          notFound: {
            title: 'Nicht gefunden',
            message: 'Die gewählte Resource konnte nicht gefunden werden!',
          },
          conflict: {
            title: 'Konflikt',
            message: 'Die gewählte Resource wurde in der Zwischenzeit verändert. Ihre Änderung konnte nicht durchgeführt werden!',
          },
          unknownInstitute: {
            title: 'Unbekanntes Institut',
            message: 'Das gewählte Institut ist dem System nicht bekannt!',
          },
          unknownUser: {
            title: 'Unbekannter Benutzer',
            message: 'Das gewählte Benutzer ist dem System nicht bekannt!',
          },
          unknownEldaMessage: {
            title: 'Unbekannte ELDA Nachricht',
            message: 'Die gewählte ELDA Nachricht ist dem System nicht bekannt!',
          },
          unknownInstituteMessage: {
            title: 'Unbekannte Institut Nachricht',
            message: 'Die gewählte Institut Nachricht ist dem System nicht bekannt!',
          },
          wrongCurrentPassword: {
            title: 'Passwortänderung fehlgeschlagen',
            message: 'Das übertragene aktuelle Passwort ist nicht korrekt!',
          },
          invalidNewPassword: {
            title: 'Passwortänderung fehlgeschlagen',
            message: 'Das neue Passwort ist nicht valid!',
          },
          operationUnsupported: {
            title: 'Aktion fehlgeschlagen',
            message: 'Die gewählte Aktion ist nicht unterstützt!',
          },
          doubleUsername: {
            title: 'Aktualisierung fehlgeschlagen',
            message: 'Der gegebene Benutzername ist bereits vergeben!',
          },
          noLookupMatches: {
            title: 'Nichts gefunden',
            message: 'Keine Nachricht für die gegebenen Angaben gefunden!',
          },
          noLookupVpnrMatches: {
            title: 'Nichts eindeutiges gefunden',
            messages: 'Mehr als eine Nachricht kommt für die Angaben in Frage. Bitte die Versicherungsnummer des Patienten angeben!',
          },
          instituteDoubleUsername: {
            title: 'Speichern fehlgeschlagen',
            message: 'Dieser FTP Benutzernamen ist bereits vergeben!',
          },
          instituteDoubleName: {
            title: 'Speichern fehlgeschlagen',
            message: 'Dieser Institutsname ist bereits vergeben!',
          },
        },
        endpointTest: {
          request: {
            title: 'Teste Verbindung',
            message: 'Die Verbindung zum Endpunkt wird getestet...'
          },
          ok: {
            title: 'Verbindung erfolgreich',
            message: 'Die Verbindung konnte erfolgreich aufgebaut werden.',
          },
          generic: {
            title: 'Verbindung fehlgeschlagen',
            message: 'Ein unerwarteter Fehler ist aufgetreten!',
          },
          connectionRefused: {
            title: 'Verbindung fehlgeschlagen',
            message: 'Server hat die Verbindung abgelehnt!',
          },
          connectionFailed: {
            title: 'Verbindung fehlgeschlagen',
            message: 'Verbindung zum Server konnte nicht aufgebaut werden!',
          },
          eldaUnavailable: {
            title: 'Verbindung fehlgeschlagen',
            message: 'Der Elda Server ist aktuell nicht verfügbar!',
          },
          wrongLoginData: {
            title: 'Verbindung fehlgeschlagen',
            message: 'Benutzername und/oder Passwort ist nicht korrekt!',
          },
          unexpectedAnswer: {
            title: 'Verbindung fehlgeschlagen',
            message: 'Elda Server hat nicht definitionskonform geantwortet!',
          },
          eldaRetError: {
            title: 'Elda Server Fehler',
            message: 'Die elda.ret Datei konnte nicht abgerufen werden!',
          },
          quitFailed: {
            title: 'Elda Server Fehler',
            message: 'Die Verbindung zum Elda Server konnte nicht beendet werden!',
          },
          fileLoadError: {
            title: 'Elda Problem',
            message: 'Es ist ein Fehler beim Abrufen einer Datei aufgetreten!',
          },
          fileSendError: {
            title: 'Elda Problem',
            message: 'Es ist ein Fehler beim Senden einer Datei aufgetreten!',
          },
        },
        fetch: {
          request: {
            title: 'Laden ...',
            message: 'Lade Daten vom Server ...',
          },
          success: {
            title: 'Laden Erfolgreich',
            message: 'Die Daten wurden erfolgreich geladen.',
          },
          error: {
            title: 'Lade-Fehler',
            message: 'Fehler beim Laden der Daten vom Server!',
          },
        },
        delete: {
          request: {
            title: 'Löschen ...',
            message: 'Lösche Daten vom Server ...',
          },
          success: {
            title: 'Löschen Erfolgreich',
            message: 'Die Daten wurden erfolgreich gelöscht.',
          },
          error: {
            title: 'Löschen Fehlgeschlagen',
            message: 'Fehler beim Löschen der Daten vom Server!',
          },
        },
        save: {
          request: {
            title: 'Speichere ...',
            message: 'Speichere Daten am Server ...',
          },
          success: {
            title: 'Speichern Erfolgreich',
            message: 'Die Daten wurden erfolgreich gespeichert.',
          },
          error: {
            title: 'Speichern Fehlgeschlagen',
            message: 'Fehler beim Speichern der Daten am Server!',
          },
        },
        validation: {
          title: 'Validierungsfehler',
          lookup: {
            missingMart: 'Die Meldungsart ist ein verpflichtendes Feld!',
            missingFileNr: 'Die File Nr ist ein verpflichtendes Feld!',
          },
        },
      },
    },
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'de',
    keySeparator: '.',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
