import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import * as notification from "../notifications";
import { fetchError, fetchRequest, fetchSuccess, fetchContentError, fetchContentRequest, fetchContentSuccess } from "./actions";
import { FtpLogActionTypes } from "./types";
import { executeRestCall, executeRestCallWithJsonAnswer, IJsonAnswer } from "../../utils/remoteUtils";

function* handleFetch(action: ReturnType<typeof fetchRequest>) {
  try {
    //yield put(notification.fetchRequestNotification());
    var token: string = yield select((state) => state.authentication.token);
    const request = action.payload;
    var url = "";
    if (request.instituteId === 0) {
      url = "api/ftplog/elda";
    } else {
      url = `api/ftplog/institute/${request.instituteId}`;
    }
    url += `?pageSize=${request.pageSize}&page=${request.page}`;
    const res: IJsonAnswer = yield call(executeRestCallWithJsonAnswer, "get", url, token);
    //yield put(notification.cleanInfoMessages());
    if (res.error) {
      yield put(notification.restResultErrorNotification(res.statusCode, notification.fetchErrorNotification()));
      yield put(fetchError(res.error));
    } else {
      //yield put(notification.fetchSuccessNotification());
      yield put(fetchSuccess(res.data));
    }
  } catch (err) {
    yield put(notification.fetchErrorNotification());
    if (err instanceof Error) {
      yield put(fetchError(err.stack!));
    } else {
      yield put(fetchError("An unknown error occured."));
    }
  }
}

function* handleFetchContent(action: ReturnType<typeof fetchContentRequest>) {
  try {
    var token: string = yield select((state) => state.authentication.token);
    const res: Response = yield call(executeRestCall, "get", `api/ftplog/content/${action.payload}`, token);
    if (res.ok) {
      const content: string = yield res.text();
      yield put(notification.fetchSuccessNotification());
      yield put(fetchContentSuccess(content));
    } else {
      yield put(notification.restResultErrorNotification(res.status, notification.fetchErrorNotification()));
      yield put(fetchContentError(""));
    }
  } catch (err) {
    yield put(notification.fetchErrorNotification());
    if (err instanceof Error) {
      yield put(fetchContentError(err.stack!));
    } else {
      yield put(fetchContentError("An unknown error occured."));
    }
  }
}

function* watchRequests() {
  yield takeEvery(FtpLogActionTypes.FETCH_REQUEST, handleFetch);
  yield takeEvery(FtpLogActionTypes.FETCH_CONTENT_REQUEST, handleFetchContent);
}

export function* ftpLogSaga() {
  yield all([fork(watchRequests)]);
}
