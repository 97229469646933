import { action } from "typesafe-actions";
import { IInstitute, IInstitutePasswordChangeRequest, InstituteActionTypes } from "./types";

export const fetchRequest = (includeDeleted: boolean) => action(InstituteActionTypes.FETCH_REQUEST, includeDeleted);
export const fetchSuccess = (data: IInstitute[]) => action(InstituteActionTypes.FETCH_SUCCESS, data);
export const fetchError = (message: string) => action(InstituteActionTypes.FETCH_ERROR, message);

export const saveRequest = (data: IInstitute) => action(InstituteActionTypes.SAVE_REQUEST, data);
export const saveSuccess = (result: IInstitute) => action(InstituteActionTypes.SAVE_SUCCESS, result);
export const saveError = (message: string) => action(InstituteActionTypes.SAVE_ERROR, message);

export const passwordChangeRequest = (request: IInstitutePasswordChangeRequest) => action(InstituteActionTypes.PASSWORD_CHANGE_REQUEST, request);
export const passwordChangeSuccess = (request: IInstitutePasswordChangeRequest) => action(InstituteActionTypes.PASSWORD_CHANGE_SUCCESS, request);
export const passwordChangeError = (message: string) => action(InstituteActionTypes.PASSWORD_CHANGE_ERROR, message);
