import { action } from "typesafe-actions";
import { IFtpLogFetchRequest, IFtpLogQueryResult, FtpLogActionTypes } from "./types";

export const fetchRequest = (request: IFtpLogFetchRequest) => action(FtpLogActionTypes.FETCH_REQUEST, request);
export const fetchSuccess = (data: IFtpLogQueryResult) => action(FtpLogActionTypes.FETCH_SUCCESS, data);
export const fetchError = (message: string) => action(FtpLogActionTypes.FETCH_ERROR, message);

export const fetchContentRequest = (logId: number) => action(FtpLogActionTypes.FETCH_CONTENT_REQUEST, logId);
export const fetchContentSuccess = (data: string) => action(FtpLogActionTypes.FETCH_CONTENT_SUCCESS, data);
export const fetchContentError = (message: string) => action(FtpLogActionTypes.FETCH_CONTENT_ERROR, message);
