import * as React from "react";
import { Modal, ModalBody, ModalTitle } from "react-bootstrap";
import { withTranslation, WithTranslation } from "react-i18next";
import LoadingSpinner from "./LoadingSpinner";

interface ILoginModalFormProps {
  show: boolean;
  onHide: () => void;
  content?: string;
  loading: boolean;
}

class MessageContentModalView extends React.Component<WithTranslation & ILoginModalFormProps> {

  private formatXML = (xml: string, tab = '\t', nl = '\n') => {
    var formatted = '';
    var indent = '';
    const nodes = xml.slice(1, -1).split(/>\s*</);
    if (nodes[0][0] === '?') {
      formatted += `<${nodes.shift()}>${nl}`;
    }
    for (let i = 0; i < nodes.length; i++) {
      var node = nodes[i];
      if (node.endsWith("\n") && node.indexOf('>') >= 0) {
        node = node.substring(0, node.indexOf('>'));
      }
      if (node[0] === '/') {
        indent = indent.slice(tab.length);
      }
      formatted += `${indent}<${node}>${nl}`;
      if (node[0] !== '/' && node[node.length - 1] !== '/' && node.indexOf('</') === -1) {
        indent += tab;
      }
    }
    return formatted;
  }

  public render() {
    const { t, show, onHide, loading, content } = this.props;
    return (
      <Modal show={show} onHide={onHide} size={"xl"}>
        <Modal.Header closeButton>
          <ModalTitle>{t("pages.messages.showContent")}</ModalTitle>
        </Modal.Header>
        <ModalBody>
          {loading && <LoadingSpinner i18nKey="progress.fetching" />}
          {!loading && !content && <div>{t("pages.messages.contentError")}</div>}
          {!loading && content && <textarea disabled={true} className="col-xl-12" rows={30} value={this.formatXML(content)}/>}
        </ModalBody>
      </Modal>
    );
  }
}

export default (withTranslation()(MessageContentModalView));
