export interface ILoginAttempt {
  username: string;
  password: string;
}

export interface ILogoutAttempt {
  token: string;
}

export interface IAuthentication {
  instituteId: number;
  institute: string;
  roles: string[];
  sysadmin: boolean;
  token: string;
  userId: number;
  userName: string;
  userFullName: string;
  valid: boolean;
}

export enum IAuthenticationActionTypes {
  LOGIN_REQUEST = "@@authentication/LOGIN_REQUEST",
  LOGIN_RESULT = "@@authentication/LOGIN_RESULT",
  LOGIN_ERROR = "@@authentication/LOGIN_ERROR",

  LOGOUT_REQUEST = "@@authentication/LOGOUT_REQUEST",
  LOGOUT_RESULT = "@@authentication/LOGOUT_RESULT",
  LOGOUT_ERROR = "@@authentication/LOGOUT_ERROR",
}

export interface IAuthenticationState {
  readonly loggedIn: boolean;
  readonly token: string;
  readonly authentication?: IAuthentication;
  readonly loading: boolean;
  readonly errors?: string;
}
