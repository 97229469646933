import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import * as notification from "../notifications";
import { fetchRequest, fetchError, fetchSuccess, saveRequest, saveError, saveSuccess } from "./actions";
import { RuleActionTypes } from "./types";
import { executeRestCallWithJsonAnswer, IJsonAnswer } from "../../utils/remoteUtils";

function* handleFetch(action: ReturnType<typeof fetchRequest>) {
  try {
    var token: string = yield select((state) => state.authentication.token);
    yield put(notification.fetchRequestNotification());
    const res: IJsonAnswer = yield call(executeRestCallWithJsonAnswer, "get", "api/splitRule", token);
    yield put(notification.cleanInfoMessages());
    if (res.error) {
      yield put(notification.fetchErrorNotification());
      yield put(fetchError(res.error));
    } else {
      yield put(notification.fetchSuccessNotification());
      yield put(fetchSuccess(res.data));
    }
  } catch (err) {
    yield put(notification.fetchErrorNotification());
    if (err instanceof Error) {
      yield put(fetchError(err.stack!));
    } else {
      yield put(fetchError("An unknown error occured."));
    }
  }
}

function* handleSave(action: ReturnType<typeof saveRequest>) {
  try {
    var token: string = yield select((state) => state.authentication.token);
    const res: IJsonAnswer = yield call(executeRestCallWithJsonAnswer, "post", "api/splitRule", token, action.payload);
    if (res.error) {
      yield put(notification.restResultErrorNotification(res.statusCode, notification.saveErrorNotification()));
      yield put(saveError(res.error));
    } else {
      yield put(notification.saveSuccessNotification());
      yield put(saveSuccess(res.data));
    }
  } catch (err) {
    yield put(notification.saveErrorNotification());
    if (err instanceof Error) {
      yield put(saveError(err.stack!));
    } else {
      yield put(saveError("An unknown error occured."));
    }
  }
}

function* watchRequest() {
  yield takeEvery(RuleActionTypes.FETCH_REQUEST, handleFetch);
  yield takeEvery(RuleActionTypes.SAVE_REQUEST, handleSave);
}

export function* ruleSaga() {
  yield all([fork(watchRequest)]);
}
