import * as React from "react";
import { Container } from "react-bootstrap";
import { withTranslation, WithTranslation } from "react-i18next";
import SplitRuleList from "../components/SplitRuleList";

const RulePage: React.FC<WithTranslation> = ({ t }) => {
  return (
    <Container fluid={true}>
      <h1>{t("pages.rules.title")}</h1>
      <SplitRuleList />
    </Container>
  );
};

export default withTranslation()(RulePage);
