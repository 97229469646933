import { Reducer } from "redux";

import { MessageActionTypes, IMessageState, IMessageQueryResult } from "./types";

const initialState: IMessageState = {
  data: {
    pageNumber: 0,
    totalPages: 0,
    elementsPerPage: 0,
    totalElementCount: 0,
    firstPage: false,
    lastPage: false,
    empty: true,
    elements: []
  },
  errors: undefined,
  loading: false,
  loadingContent: false,
  messageContent: undefined,
};

const reducer: Reducer<IMessageState> = (state = initialState, action) => {
  switch (action.type) {
    case MessageActionTypes.FETCH_REQUEST: {
      const updatedData: IMessageQueryResult = {
        pageNumber: 0,
        totalPages: 0,
        elementsPerPage: 0,
        totalElementCount: 0,
        firstPage: false,
        lastPage: false,
        empty: true,
        elements: []
      }
      return { ...state, errors: undefined, loading: true, data: updatedData };
    }
    case MessageActionTypes.FETCH_SUCCESS: {
      return { ...state, errors: undefined, loading: false, data: action.payload };
    }
    case MessageActionTypes.FETCH_ERROR: {
      return { ...state, loading: false, errors: action.payload };
    }
    case MessageActionTypes.RETRANSMIT_SUCCESS: {
      const index = state.data.elements.findIndex((item) => item.id === action.payload.id);
      if (index === -1) {
        return state;
      }
      const updatedElements = state.data.elements.map((item, innerIndex) => {
        if (innerIndex !== index) {
          return item;
        }
        return {
          ...item,
          status: 'WAIT_FOR_TRANSMISSION',
        };
      });
      return {
        ...state,
        data: {
          ...state.data,
          elements: updatedElements
        },
      };
    }
    case MessageActionTypes.FETCH_CONTENT_REQUEST: {
      return { ...state, loadingContent: true, messageContent: undefined };
    }
    case MessageActionTypes.FETCH_CONTENT_SUCCESS: {
      return { ...state, loadingContent: false, messageContent: action.payload };
    }
    case MessageActionTypes.FETCH_CONTENT_ERROR: {
      return { ...state, loadingContent: false, messageContent: undefined };
    }
    case MessageActionTypes.CLEAR_MESSAGES: {
      const updatedData: IMessageQueryResult = {
        pageNumber: 0,
        totalPages: 0,
        elementsPerPage: 0,
        totalElementCount: 0,
        firstPage: false,
        lastPage: false,
        empty: true,
        elements: []
      }
      return { ...state, errors: undefined, loading: false, data: updatedData };
    }
    case MessageActionTypes.LOOKUP_REQUEST: {
      const updatedData: IMessageQueryResult = {
        pageNumber: 0,
        totalPages: 0,
        elementsPerPage: 0,
        totalElementCount: 0,
        firstPage: false,
        lastPage: false,
        empty: true,
        elements: []
      }
      return { ...state, errors: undefined, loading: true, data: updatedData };
    }
    case MessageActionTypes.LOOKUP_RESULT: {
      const updatedData: IMessageQueryResult = {
        pageNumber: 1,
        totalPages: 1,
        elementsPerPage: 1,
        totalElementCount: 1,
        firstPage: true,
        lastPage: true,
        empty: false,
        elements: [action.payload]
      }
      return { ...state, loading: false, data: updatedData };
    }
    case MessageActionTypes.LOOKUP_ERROR: {
      return { ...state, loading: false, errors: action.payload };
    }
    default: {
      return state;
    }
  }
};

export { reducer as messageReducer };
