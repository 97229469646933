import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";

import * as notification from "../notifications";
import { fetchError, fetchRequest, fetchSuccess, saveError, saveRequest, saveSuccess, testRequest } from "./actions";
import { EndpointActionTypes } from "./types";
import { executeRestCall, executeRestCallWithJsonAnswer, IJsonAnswer } from "../../utils/remoteUtils";
import { deleteError, deleteRequest, deleteSucess } from ".";

function* handleFetch(action: ReturnType<typeof fetchRequest>) {
  try {
    var token: string = yield select((state) => state.authentication.token);
    const res: IJsonAnswer = yield call(executeRestCallWithJsonAnswer, "get", "api/eldaEndpoint", token);
    yield put(notification.cleanInfoMessages());
    if (res.error) {
      yield put(notification.fetchErrorNotification());
      yield put(fetchError(res.error));
    } else {
      yield put(notification.fetchSuccessNotification());
      yield put(fetchSuccess(res.data));
    }
  } catch (err) {
    yield put(notification.fetchErrorNotification());
    if (err instanceof Error) {
      yield put(fetchError(err.stack!));
    } else {
      yield put(fetchError("An unknown error occured."));
    }
  }
}

function* handleSave(action: ReturnType<typeof saveRequest>) {
  try {
    var token: string = yield select((state) => state.authentication.token);
    const res: IJsonAnswer = yield call(executeRestCallWithJsonAnswer, "post", "api/eldaEndpoint", token, action.payload);
    if (res.error) {
      yield put(notification.saveErrorNotification());
      yield put(saveError(res.error));
    } else {
      yield put(notification.saveSuccessNotification());
      yield put(saveSuccess(res.data));
    }
  } catch (err) {
    yield put(notification.saveErrorNotification());
    if (err instanceof Error) {
      yield put(saveError(err.stack!));
    } else {
      yield put(saveError("An unknown error occured."));
    }
  }
}

function* handleDelete(action: ReturnType<typeof deleteRequest>) {
  try {
    var token: string = yield select((state) => state.authentication.token);
    const res: Response = yield call(executeRestCall, "delete", `api/eldaEndpoint/${action.payload.id}`, token);
    if (!res.ok) {
      yield put(notification.deleteErrorNotification());
      yield put(deleteError(""));
    } else {
      yield put(notification.deleteSuccessNotification());
      yield put(deleteSucess(action.payload));
    }
  } catch (err) {
    yield put(notification.deleteErrorNotification());
    if (err instanceof Error) {
      yield put(saveError(err.stack!));
    } else {
      yield put(saveError("An unknown error occured."));
    }
  }
}

function* handleTest(action: ReturnType<typeof testRequest>) {
  try {
    yield put(notification.testEndpointRequestNotification());
    var token: string = yield select((state) => state.authentication.token);
    const res: Response = yield call(executeRestCall, "post", "api/eldaEndpoint/test", token, action.payload);
    yield put(notification.cleanInfoMessages());
    if (res.ok) {
      yield put(notification.eldaEndpointTestSuccess());
    } else {
      yield put(notification.eldaEndpointTestError(res.status));
    }
  } catch (err) {
    yield put(notification.eldaEndpointTestError(42));
  }
}

function* watchRequests() {
  yield takeEvery(EndpointActionTypes.FETCH_REQUEST, handleFetch);
  yield takeEvery(EndpointActionTypes.SAVE_REQUEST, handleSave);
  yield takeEvery(EndpointActionTypes.TEST_REQUEST, handleTest);
  yield takeEvery(EndpointActionTypes.DELETE_REQUEST, handleDelete);
}

export function* endpointSaga() {
  yield all([fork(watchRequests)]);
}
