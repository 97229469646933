import { action } from "typesafe-actions";
import { IMessage, IMessageContentFetchRequest, IMessageFetchRequest, IMessageLookupRequest, IMessageQueryResult, IRetransmitRequest, MessageActionTypes } from "./types";

export const fetchRequest = (request: IMessageFetchRequest) => action(MessageActionTypes.FETCH_REQUEST, request);
export const fetchSuccess = (data: IMessageQueryResult) => action(MessageActionTypes.FETCH_SUCCESS, data);
export const fetchError = (message: string) => action(MessageActionTypes.FETCH_ERROR, message);

export const retransmitRequest = (request: IRetransmitRequest) => action(MessageActionTypes.RETRANSMIT_REQUEST, request);
export const retransmitSuccess = (message: IMessage) => action(MessageActionTypes.RETRANSMIT_SUCCESS, message);

export const fetchMessageContent = (request: IMessageContentFetchRequest) => action(MessageActionTypes.FETCH_CONTENT_REQUEST, request);
export const fetchMessageContentSuccess = (data: string) => action(MessageActionTypes.FETCH_CONTENT_SUCCESS, data);
export const fetchMessageContentError = (message: string) => action(MessageActionTypes.FETCH_CONTENT_ERROR, message);

export const lookupRequest = (request: IMessageLookupRequest) => action(MessageActionTypes.LOOKUP_REQUEST, request);
export const lookupResult = (message: IMessage) => action(MessageActionTypes.LOOKUP_RESULT, message);
export const lookupError = (error: string) => action(MessageActionTypes.LOOKUP_ERROR, error);

export const clearMessages = () => action(MessageActionTypes.CLEAR_MESSAGES);
