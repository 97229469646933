import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import SplitRuleSetForm from "../form/SplitRuleSetForm";
import { IApplicationState } from "../store";
import * as instituteActions from "../store/institute/actions";
import { IInstitute } from "../store/institute/types";
import * as ruleActions from "../store/rules/actions";
import { ISplitRuleSet } from "../store/rules/types";
import * as settingActions from "../store/settings/actions";
import { IFrontendSettings } from "../store/settings/types";
import LoadingSpinner from "./LoadingSpinner";

interface IPropertiesFromState {
  data?: ISplitRuleSet;
  loading: boolean;
  errors?: string;
  institutes: IInstitute[]
  settings?: IFrontendSettings;
}

interface IPropertiesFromDispatch {
  fetchRequest: typeof ruleActions.fetchRequest;
  saveRequest: typeof ruleActions.saveRequest;
  addRule: typeof ruleActions.addRule;
  removeRule: typeof ruleActions.removeRule;
  moveRuleUp: typeof ruleActions.moveRuleUp;
  moveRuleDown: typeof ruleActions.moveRuleDown;
  fetchInstituteRequest: typeof instituteActions.fetchRequest;
  fetchSettings: typeof settingActions.fetchRequest;
}

class SplitRuleList extends React.Component<IPropertiesFromState & IPropertiesFromDispatch> {

  public componentDidMount() {
    this.props.fetchRequest();
    this.props.fetchInstituteRequest(false);
    this.props.fetchSettings();
  }

  public render() {
    const { data, loading, saveRequest } = this.props;

    const handleCloseModal = () => {
      this.setState({ showEditModal: false, showPasswordModal: false });
    };

    const submitChanges = (ruleSet: ISplitRuleSet) => {
      saveRequest(ruleSet);
      handleCloseModal();
    };

    if (loading) {
      return <LoadingSpinner i18nKey="progress.fetching" />;
    }
    return (
      <div>
        <SplitRuleSetForm
          onSubmit={(submitChanges)}
          initialValues={data}
          addRule={() => this.props.addRule()}
          removeRule={(index: number) => this.props.removeRule(index)}
          moveRuleUp={(index: number) => this.props.moveRuleUp(index)}
          moveRuleDown={(index: number) => this.props.moveRuleDown(index)}
          ruleAmount={this.props.data !==  undefined ? this.props.data?.rules.length : 0}
          institutes={this.props.institutes}
          showDefaultInstitute={this.props.settings ? !this.props.settings.noDefaultInstitute : true}
        />
      </div>
    ); 
  }
}

const mapStateToProps = (store: IApplicationState) => {
  return {
    data: store.rules.data,
    loading: store.rules.loading,
    errors: store.rules.errors,
    institutes: store.institute.data,
    settings: store.settings.data,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchRequest: () => dispatch(ruleActions.fetchRequest()),
  saveRequest: (ruleSet: ISplitRuleSet) => dispatch(ruleActions.saveRequest(ruleSet)),
  addRule: () => dispatch(ruleActions.addRule()),
  removeRule: (index: number) => dispatch(ruleActions.removeRule(index)),
  moveRuleUp: (index: number) => dispatch(ruleActions.moveRuleUp(index)),
  moveRuleDown: (index: number) => dispatch(ruleActions.moveRuleDown(index)),
  fetchInstituteRequest: (includeDeleted: boolean) => dispatch(instituteActions.fetchRequest(includeDeleted)),
  fetchSettings: () => dispatch(settingActions.fetchRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SplitRuleList);
