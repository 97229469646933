import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Form, Table } from "react-bootstrap";
import { withTranslation, WithTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import { Field, FieldArray, WrappedFieldArrayProps, GenericFieldArray, InjectedFormProps, reduxForm  } from "redux-form";
import { initialize } from "redux-form";
import { plusIcon, trashIcon, arrowDown, arrowUp } from "../styles/icons";
import { ISplitRuleSet } from "../store/rules/types";
import { IInstitute } from "../store/institute/types";
import BootstrapSelectFormGroup from "./BootstrapSelectFormGroup";
import BootstrapFormGroup from "./BootstrapFormGroup";

interface ISplitRuleFormProperties {
  addRule: () => void;
  removeRule: (index: number) => void;
  moveRuleUp: (index: number) => void;
  moveRuleDown: (index: number) => void;
  ruleAmount: number;
  institutes: IInstitute[];
  showDefaultInstitute: boolean;
}

class SplitRuleSetForm extends React.Component<InjectedFormProps<ISplitRuleSet, ISplitRuleFormProperties> & ISplitRuleFormProperties & WithTranslation> {

  public render() {
    const { submitting, handleSubmit, t, addRule, removeRule, moveRuleUp, moveRuleDown, ruleAmount, institutes, showDefaultInstitute } = this.props;

    const FieldArrayCustom = FieldArray as new () => GenericFieldArray<Field, any>;

    const renderRules = (props: WrappedFieldArrayProps<{}>) => {        
      const { fields } = props;

      return (
        <>
          {fields.map((member, index) => (
            <tr key={index}>
              <td>
                <Field
                  name={`${member}.criteria`}
                  type="text"
                  component={BootstrapSelectFormGroup}
                  placeholder={t("pages.rules.criteriaPlaceholder")}
                  options={[
                    {value: "TARIFKATEGORIE", label: "pages.rules.categories.tarifkategorie"}
                  ]}
                />
              </td>
              <td>
                <Field
                  name={`${member}.value`}
                  type="text"
                  component={BootstrapFormGroup}
                />
              </td>
              <td>
                <Field
                  name={`${member}.instituteFk`}
                  type="number"
                  component={BootstrapSelectFormGroup}
                  placeholder={t("pages.rules.institutePlaceholder")}
                  options={
                    institutes && institutes.map((institute) => {
                      return { value: institute.id, label: institute.name };
                    })
                  }
                />
              </td>
              <td>
                <Button variant="link" onClick={() => removeRule(index)} title={t("pages.rules.removeRule")}>
                  <FontAwesomeIcon icon={trashIcon} pull="right"/>
                </Button>
                {index !== 0 && <Button variant="link" onClick={() => moveRuleUp(index)} title={t("pages.rules.moveRuleUp")}>
                  <FontAwesomeIcon icon={arrowUp} pull="right"/>
                </Button>}
                {index !== ruleAmount - 1 && <Button variant="link" onClick={() => moveRuleDown(index)} title={t("pages.rules.moveRuleDown")}>
                  <FontAwesomeIcon icon={arrowDown} pull="right"/>
                </Button>}
              </td>
            </tr>
          ))}
        </>
      );

    };

    return (
      <Form onSubmit={handleSubmit}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>{t("pages.rules.criteria")}</th>
              <th>{t("pages.rules.value")}</th>
              <th>{t("pages.rules.institute")}</th>
              <th>
                <Button variant="link" onClick={() => addRule()} title={t("pages.rules.addRule")}>
                  <FontAwesomeIcon icon={plusIcon} pull="right"/>
                </Button>
              </th>
            </tr>
          </thead>
          <tbody>
            <FieldArrayCustom name="rules" component={renderRules} />
            {showDefaultInstitute && <tr>
              <td>{t("pages.rules.default")}</td>
              <td/>
              <td>
                <Field
                  name={"defaultInstitute"}
                  type="number"
                  component={BootstrapSelectFormGroup}
                  placeholder={t("pages.rules.institutePlaceholder")}
                  options={
                    institutes && institutes.map((institute) => {
                      return { value: institute.id, label: institute.name };
                    })
                  }/>
              </td>
              <td/>
            </tr>}
          </tbody>
        </Table>
                
        <Row>
          <Col xs={2}/>
          <Col xs={2}>
            <Button variant="primary" type="submit" disabled={submitting}>
              {t("form.general.update")}
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

export const initRuleFormAction = (ruleSet: Partial<ISplitRuleSet>) => {
  return initialize("ruleForm", ruleSet);
};

export default reduxForm<ISplitRuleSet, ISplitRuleFormProperties>({
  form: "ruleForm",
  enableReinitialize: true,
}) (withTranslation() (SplitRuleSetForm));
