import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { Button, Table } from "react-bootstrap";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import InstituteModalForm, { initInstituteModalFormAction } from "../form/InstituteModalForm";
import * as notification from "../store/notifications";
import { IApplicationState } from "../store";
import * as instituteActions from "../store/institute/actions";
import { IInstitute, IInstitutePasswordChangeRequest, INSTITUTE_COMMUNICATION_MODE } from "../store/institute/types";
import { editIcon, keyIcon } from "../styles/icons";
import LoadingSpinner from "./LoadingSpinner";
import ChangeInstitutePasswordForm, { initChangeInstitutePasswordModalFormAction } from "../form/ChangeInstitutePasswordForm";

interface IPropertiesFromState {
  data: IInstitute[];
  loading: boolean;
  errors?: string;
}

interface IPropertiesFromDispatch {
  fetchRequest: typeof instituteActions.fetchRequest;
  saveRequest: typeof instituteActions.saveRequest;
  updatePasswordRequest: typeof instituteActions.passwordChangeRequest;
  initInstituteForm: typeof initInstituteModalFormAction;
  initInstitutePasswordForm: typeof initChangeInstitutePasswordModalFormAction;
  newCredentialsNotMatchingNotification: typeof notification.newCredentialsNotMatchingNotification;
}

interface IInstituteListState {
  createInstitute: boolean;
  showEditModal: boolean;
  showPasswordModal: boolean;
  lastSelectedInstitute: number;
}

class InstituteList extends React.Component<IPropertiesFromState & IPropertiesFromDispatch & WithTranslation, IInstituteListState> {
  public readonly state: IInstituteListState = {
    createInstitute: true,
    showEditModal: false,
    showPasswordModal: false,
    lastSelectedInstitute: 0,
  };

  public componentDidMount() {
    this.props.fetchRequest(true);
  }

  public render() {
    const { data, loading, t, saveRequest, updatePasswordRequest, initInstituteForm, initInstitutePasswordForm, newCredentialsNotMatchingNotification } = this.props;

    const handleCloseModal = () => {
      this.setState({ showEditModal: false, showPasswordModal: false });
    };

    const handleCreate = () => {
      initInstituteForm({});
      this.setState({ showEditModal: true, createInstitute: true, showPasswordModal: false });
    };

    const handleEdit = (institute: IInstitute) => {
      initInstituteForm(institute);
      this.setState({ showEditModal: true, createInstitute: false, showPasswordModal: false });
    };

    const submitChanges = (institute: IInstitute) => {
      saveRequest(institute);
      handleCloseModal();
    };

    const showPasswordChange = (institute: IInstitute) => {
      initInstitutePasswordForm({});
      this.setState({ lastSelectedInstitute: institute.id, showEditModal: false, showPasswordModal: true });
    }

    const changePassword = (passwordRequest: IInstitutePasswordChangeRequest) => {
      if (passwordRequest.password !== passwordRequest.passwordRepeat) {
        newCredentialsNotMatchingNotification();
        return;
      }
      // i tried to set the instituteId in the init form method, but then everything went wrong. Every change I did, could not be written in the result object.
      passwordRequest.instituteId = this.state.lastSelectedInstitute;
      updatePasswordRequest(passwordRequest);
      handleCloseModal();
    }

    if (loading) {
      return <LoadingSpinner i18nKey="progress.fetching" />;
    }
    return (
      <div>
        {(!data || data.length === 0) && <div className="mb-3">{t("pages.institutes.noData")}</div>}
        {data && data.length > 0 && <Table striped bordered hover>
          <thead>
            <tr>
              <th>{t("pages.institutes.name")}</th>
              <th>{t("pages.institutes.communicationMode")}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {data.map((institute) => {
              return (
                <tr key={institute.id}>
                  <td>{institute.name}</td>
                  <td>{t(`pages.institutes.communicationModes.${institute.communicationMode}`)}</td>
                  <td>
                    <div>
                      <Button variant="link" onClick={() => handleEdit(institute)} title={t("pages.institutes.editInstitute")}>
                        <FontAwesomeIcon icon={editIcon} pull="right"/>
                      </Button>
                      {institute.communicationMode === INSTITUTE_COMMUNICATION_MODE.FTP && 
                        <Button variant="link" onClick={() => showPasswordChange(institute)} title={t("pages.institutes.changePassword")}>
                          <FontAwesomeIcon icon={keyIcon} pull="right"/>
                        </Button>
                      }
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>}
        <Button variant="outline-info" onClick={handleCreate}>
          {t("pages.institutes.addInstitute")}
        </Button>
        <InstituteModalForm
          onSubmit={(submitChanges)}
          initialValues={{}}
          createInstitute={this.state.createInstitute}
          show={this.state.showEditModal}
          onHide={handleCloseModal}
        />
        <ChangeInstitutePasswordForm
          onSubmit={(changePassword)}
          initialValues={{}}
          show={this.state.showPasswordModal}
          onHide={handleCloseModal}
        />
      </div>
    ); 
  }
}

// Grab the characters from the store and make them available on props
const mapStateToProps = (store: IApplicationState) => {
  return {
    data: store.institute.data,
    loading: store.institute.loading,
    errors: store.institute.errors,
  };
};

// Mapping the actions to the props to allow calling them in the component
const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchRequest: (includeDeleted: boolean) => dispatch(instituteActions.fetchRequest(includeDeleted)),
  saveRequest: (institute: IInstitute) => dispatch(instituteActions.saveRequest(institute)),
  updatePasswordRequest: (request: IInstitutePasswordChangeRequest) => dispatch(instituteActions.passwordChangeRequest(request)),
  initInstituteForm: (institute: Partial<IInstitute>) => dispatch(initInstituteModalFormAction(institute)),
  initInstitutePasswordForm: (request: Partial<IInstitutePasswordChangeRequest>) => dispatch(initChangeInstitutePasswordModalFormAction(request)),
  newCredentialsNotMatchingNotification: () => dispatch(notification.newCredentialsNotMatchingNotification()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InstituteList));
