import * as React from "react";
import { Modal, ModalBody, ModalTitle } from "react-bootstrap";
import { withTranslation, WithTranslation } from "react-i18next";
import LoadingSpinner from "./LoadingSpinner";

interface ILoginModalFormProps {
  show: boolean;
  onHide: () => void;
  content?: string;
  loading: boolean;
}

class FtpLogModalView extends React.Component<WithTranslation & ILoginModalFormProps> {
  public render() {
    const { t, show, onHide, loading, content } = this.props;
    return (
      <Modal show={show} onHide={onHide} size={"xl"}>
        <Modal.Header closeButton>
          <ModalTitle>{t("pages.ftpLog.showLog")}</ModalTitle>
        </Modal.Header>
        <ModalBody>
          {loading && <LoadingSpinner i18nKey="progress.fetching" />}
          {!loading && !content && <div>{t("pages.ftpLog.modalError")}</div>}
          {!loading && content && <textarea disabled={true} className="col-xl-12" rows={30} value={content}/>}
        </ModalBody>
      </Modal>
    );
  }
}

export default (withTranslation()(FtpLogModalView));
