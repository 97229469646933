export interface ISplitRule {
  edikurVersion: string;
  criteria: string;
  value: string;
  instituteFk: number;
}

export interface ISplitRuleSet {
  rules: ISplitRule[];
  defaultInstitute: number;
}

export enum RuleActionTypes {
  FETCH_REQUEST = "@@rules/FETCH_REQUEST",
  FETCH_SUCCESS = "@@rules/FETCH_SUCCESS",
  FETCH_ERROR = "@@rules/FETCH_ERROR",

  SAVE_REQUEST = "@@rules/SAVE_REQUEST",
  SAVE_SUCCESS = "@@rules/SAVE_SUCCESS",
  SAVE_ERROR = "@@rules/SAVE_ERROR",

  ADD_RULE = "@@rules/ADD_RULE",
  REMOVE_RULE = "@@rules/REMOVE_RULE",
  MOVE_RULE_UP = "@@rules/MOVE_RULE_UP",
  MOVE_RULE_DOWN = "@@rules/MOVE_RULE_DOWN",
}

export interface IRuleState {
  readonly loading: boolean;
  readonly data?: ISplitRuleSet;
  readonly errors?: string;
}
