import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import * as notification from "../notifications";
import { fetchRequest, fetchError, fetchSuccess, saveRequest, saveError, saveSuccess, passwordChangeRequest, passwordChangeSuccess, passwordChangeError } from "./actions";
import { UserActionTypes } from "./types";
import { executeRestCall, executeRestCallWithJsonAnswer, IJsonAnswer } from "../../utils/remoteUtils";

function* handleFetch(action: ReturnType<typeof fetchRequest>) {
  try {
    var token: string = yield select((state) => state.authentication.token);
    yield put(notification.fetchRequestNotification());
    const res: IJsonAnswer = yield call(executeRestCallWithJsonAnswer, "get", `api/user?includeDeleted=${action.payload}`, token);
    yield put(notification.cleanInfoMessages());
    if (res.error) {
      yield put(notification.fetchErrorNotification());
      yield put(fetchError(res.error));
    } else {
      yield put(notification.fetchSuccessNotification());
      yield put(fetchSuccess(res.data));
    }
  } catch (err) {
    yield put(notification.fetchErrorNotification());
    if (err instanceof Error) {
      yield put(fetchError(err.stack!));
    } else {
      yield put(fetchError("An unknown error occured."));
    }
  }
}

function* handleSave(action: ReturnType<typeof saveRequest>) {
  try {
    var token: string = yield select((state) => state.authentication.token);
    const res: IJsonAnswer = yield call(executeRestCallWithJsonAnswer, "post", "api/user", token, action.payload);
    if (res.error) {
      yield put(notification.restResultErrorNotification(res.statusCode, action.payload.id === 0 ? notification.instituteCreateFailureNotification() : notification.instituteUpdateFailureNotification()));
      yield put(saveError(res.error));
    } else {
      yield put(action.payload.id === 0 ? notification.instituteCreateSuccessNotification() : notification.instituteUpdateSuccessNotification());
      yield put(saveSuccess(res.data));
    }
  } catch (err) {
    yield put(notification.saveErrorNotification());
    if (err instanceof Error) {
      yield put(saveError(err.stack!));
    } else {
      yield put(saveError("An unknown error occured."));
    }
  }
}

function* handlePasswordChange(action: ReturnType<typeof passwordChangeRequest>) {
  try {
    var token: string = yield select((state) => state.authentication.token);
    const res: Response = yield call(executeRestCall, "post", `api/user/changePassword`, token, action.payload);
    if (res.ok) {
      yield put(passwordChangeSuccess(action.payload));
      yield put(notification.passwordChangeSuccessNotification());
    } else {
      yield put(passwordChangeError(""));
      yield put(notification.restResultErrorNotification(res.status, notification.passwordChangeErrorNotification()));
    }
  } catch (err) {
    yield put(notification.passwordChangeErrorNotification());
    if (err instanceof Error) {
      yield put(passwordChangeError(err.stack!));
    } else {
      yield put(passwordChangeError("An unknown error occured."));
    }
  }
}

function* watchRequest() {
  yield takeEvery(UserActionTypes.FETCH_REQUEST, handleFetch);
  yield takeEvery(UserActionTypes.SAVE_REQUEST, handleSave);
  yield takeEvery(UserActionTypes.PASSWORD_CHANGE_REQUEST, handlePasswordChange);
}

export function* userSaga() {
  yield all([fork(watchRequest)]);
}
