import * as React from "react";
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap";
import { withTranslation, WithTranslation } from "react-i18next";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { initialize } from "redux-form";

import { IPasswordChangeRequest } from "../store/user/types";
import BootstrapFormGroup from "./BootstrapFormGroup";

interface IChangePasswordModalFormProps {
  show: boolean;
  onHide: () => void;
}

class ChangePasswordModalForm extends React.Component<InjectedFormProps<IPasswordChangeRequest, IChangePasswordModalFormProps> & WithTranslation & IChangePasswordModalFormProps> {
  public render() {
    const { pristine, submitting, handleSubmit, t, show, onHide } = this.props;
    return (
      <Modal show={show} onHide={onHide}>
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <ModalTitle>{t("pages.passwordChange.title")}</ModalTitle>
          </Modal.Header>
          <ModalBody>
            <Field
                name="oldPassword"
                component={BootstrapFormGroup}
                label = {t("pages.passwordChange.oldPassword")}
                placeholder = {t("pages.passwordChange.editor.oldPasswordPlaceholder")}
                type = "password"
            />
            <Field
                name="newPassword"
                component={BootstrapFormGroup}
                label = {t("pages.passwordChange.newPassword")}
                placeholder = {t("pages.passwordChange.editor.newPasswordPlaceholder")}
                type = "password"
            />
            <Field
                name="newPasswordRepeat"
                component={BootstrapFormGroup}
                label = {t("pages.passwordChange.newPasswordRepeat")}
                placeholder = {t("pages.passwordChange.editor.newPasswordRepeatPlaceholder")}
                type = "password"
            />
          </ModalBody>
          <ModalFooter>
            <Button variant="primary" type="submit" disabled={pristine || submitting}>
              {t("form.general.changePassword")}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}

export const initChangePasswordModalFormAction = (loginAttempt: Partial<IPasswordChangeRequest>) => {
  return initialize("passwordChangeFormModal", loginAttempt);
};

export default reduxForm<IPasswordChangeRequest, IChangePasswordModalFormProps>({
  form: "passwordChangeFormModal",
  enableReinitialize: true,
})(withTranslation()(ChangePasswordModalForm));
