import * as React from "react";
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap";
import { withTranslation, WithTranslation } from "react-i18next";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { initialize } from "redux-form";
import { IInstitute } from "../store/institute/types";
import BootstrapCheckbox from "./BootstrapCheckbox";
import BootstrapFormGroup from "./BootstrapFormGroup";
import BootstrapSelectFormGroup from "./BootstrapSelectFormGroup";

interface IInstituteModalFormProps {
  show: boolean;
  onHide: () => void;
  createInstitute: boolean;
}

class InstituteModalForm extends React.Component<InjectedFormProps<IInstitute, IInstituteModalFormProps> & WithTranslation & IInstituteModalFormProps> {
  public render() {
    const { pristine, submitting, reset, handleSubmit, t, show, onHide, createInstitute  } = this.props;
    return (
      <Modal show={show} onHide={onHide}>
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <ModalTitle>{t(createInstitute ? "pages.institutes.editor.create" : "pages.institutes.editor.edit")}</ModalTitle>
          </Modal.Header>
          <ModalBody>
            <Field
              name="name"
              component={BootstrapFormGroup}
              label={t("pages.institutes.name")}
              placeholder={t("pages.institutes.editor.namePlaceholder")}
            />
            <Field
              name="communicationMode"
              component={BootstrapSelectFormGroup}
              options={[
                  {label: t("pages.institutes.communicationModes.FTP"), value: "FTP"},
                  {label: t("pages.institutes.communicationModes.FILE"), value: "FILE"},
                  {label: t("pages.institutes.communicationModes.NONE"), value: "NONE"},
              ]}
              label={t("pages.institutes.communicationMode")}
            />
            <Field
              name="noMessageModification"
              component={BootstrapCheckbox}
              label={t("pages.institutes.noMessageModification")}
              type="checkbox"
            />

            <Field
              name="ftpUsername"
              component={BootstrapFormGroup}
              label={t("pages.institutes.ftpUsername")}
              placeholder={t("pages.institutes.editor.usernamePlaceholder")}
            />
            {createInstitute && <Field
              name="ftpPassword"
              component={BootstrapFormGroup}
              label={t("pages.institutes.ftpPassword")}
              placeholder={t("pages.institutes.editor.passwordPlaceholder")}
              type="password"
            />}

            <Field
              name="folderIncoming"
              component={BootstrapFormGroup}
              label={t("pages.institutes.folderIncoming")}
            />
            <Field
              name="folderOutgoing"
              component={BootstrapFormGroup}
              label={t("pages.institutes.folderOutgoing")}
            />
            <Field
              name="filePrefix"
              component={BootstrapFormGroup}
              label={t("pages.institutes.filePrefix")}
            />
            <Field
              name="filePostfix"
              component={BootstrapFormGroup}
              label={t("pages.institutes.filePostfix")}
            />
            <Field
              name="seperateErrorLog"
              component={BootstrapCheckbox}
              label={t("pages.institutes.seperateErrorLog")}
              type="checkbox"
            />
            <Field
              name="moveBrokenOutgoing"
              component={BootstrapCheckbox}
              label={t("pages.institutes.moveBrokenOutgoing")}
              type="checkbox"
            />
          </ModalBody>
          <ModalFooter>
            <Button variant="secondary" type="button" disabled={pristine || submitting} onClick={reset}>
              {t("form.general.revert")}
            </Button>
            <Button variant="primary" type="submit" disabled={pristine || submitting}>
              {t(createInstitute ? "form.general.create" : "form.general.update")}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}

export const initInstituteModalFormAction = (institute: Partial<IInstitute>) => {
  return initialize("instituteFormModal", institute);
};

export default reduxForm<IInstitute, IInstituteModalFormProps>({
  form: "instituteFormModal",
})(withTranslation()(InstituteModalForm));
