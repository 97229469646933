import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import * as React from "react";
import { Button, Table } from "react-bootstrap";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { IApplicationState } from "../store";
import { IFtpLogQueryResult, IFtpLogFetchRequest, CONSTANTS, IFtpLogEntry } from "../store/ftplog";
import * as logActions from "../store/ftplog/actions";
import { openIcon } from "../styles/icons";
import LoadingSpinner from "./LoadingSpinner";
import FtpLogModalView from "./FtpLogModalView";
import MaxLengthText from "./MaxLengthText";

interface IPropertiesFromState {
  data: IFtpLogQueryResult;
  loading: boolean;
  errors?: string;
  contentLoading: boolean;
  content?: string;
}

interface IPropertiesFromDispatch {
  fetchLogs: typeof logActions.fetchRequest;
  fetchLogContent: typeof logActions.fetchContentRequest;
}

interface IFtpLogListParameters {
  instituteId: number;
}

interface IFtpLogListState {
  showModal: boolean;
}

class FtpLogList extends React.Component<IPropertiesFromState & IPropertiesFromDispatch & IFtpLogListParameters & WithTranslation, IFtpLogListState> {

  public readonly state: IFtpLogListState = {
    showModal: false,
  };

  public render() {
    const { data, loading, errors, t, instituteId, fetchLogs, fetchLogContent, contentLoading, content } = this.props;

    const loadPage = (pageNumber: number) => {
      fetchLogs({instituteId: instituteId, pageSize: 10, page: pageNumber});
    };

    const handleCloseModal = () => {
      this.setState({ showModal: false });
    };

    const openModal = (logEntry: IFtpLogEntry) => {
      fetchLogContent(logEntry.id)
      this.setState({ showModal: true});
    }

    const calculateMessageArrayForPaging = () => {
      const maxPages = 20;
      var startPage = 0;
      var endPage = 0;
      if (data.totalPages <= maxPages) {
        startPage = 1;
        endPage = data.totalPages;
      } else {
        const maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
        const maxPagesAfterCurrentPage = Math.floor(maxPages / 2) - 1;
        if (data.pageNumber <= maxPagesBeforeCurrentPage) {
          // current page near the start
          startPage = 1;
          endPage = maxPages;
        } else if (data.pageNumber + maxPagesAfterCurrentPage >= data.totalPages) {
          // current page near the end
          startPage = data.totalPages - maxPages + 1;
          endPage = data.totalPages;
        } else {
          // current page is somewhere in the middle
          startPage = data.pageNumber - maxPagesBeforeCurrentPage;
          endPage = data.pageNumber + maxPagesAfterCurrentPage;
        }
      }
      return Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);
    };

    if (loading) {
      return <LoadingSpinner i18nKey="progress.fetching" />;
    }
    if (errors !== undefined) {
      return <div><b>{t("pages.ftpLog.loadError")}</b></div>
    }
    if (instituteId === -1) {
      return <div>{t("pages.ftpLog.selectOrigin")}</div>
    }
    if (!data || data.empty === true) {
      return <div>{t("pages.ftpLog.noData")}</div>;
    }
    return (
      <div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>{t("pages.ftpLog.connectTime")}</th>
              <th>{t("pages.ftpLog.success")}</th>
              <th>{t("pages.ftpLog.eldaRetContent")}</th>
              <th>{t("pages.ftpLog.downloads")}</th>
              <th>{t("pages.ftpLog.uploads")}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {data && data.elements.map((logEntry) => {
              return (
                <tr key={logEntry.id}>
                  <td>{moment(logEntry.connectTime).format("DD.MM.YYYY HH:mm:ss")}</td>
                  <td title={logEntry.errorMessage}>{t(`base.bool.${logEntry.success}`)}</td>
                  <td title={logEntry.eldaRetContent}>{logEntry.eldaRetContent.length > 40 ? logEntry.eldaRetContent.substring(0, 37) + "..." : logEntry.eldaRetContent === CONSTANTS.NOT_REQUESTED ? t('pages.ftpLog.notRequested') : logEntry.eldaRetContent }</td>
                  <td><MaxLengthText text={logEntry.downloads.join(",")} maxLength={40} /></td>
                  <td><MaxLengthText text={logEntry.uploads.join(",")} maxLength={40} /></td>
                  <td>
                    <div>
                        <Button variant="link" onClick={() => openModal(logEntry)} title={t("pages.ftpLog.showLog")}>
                          <FontAwesomeIcon icon={openIcon} pull="right"/>
                        </Button>
                    </div> 
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        {data && data.totalPages > 1 && <div>
          <Pagination listClassName="rowNoPadding">
              <PaginationItem disabled={data.firstPage}>
                <PaginationLink first onClick={() => loadPage(0)}/>
              </PaginationItem>
              <PaginationItem disabled={data.firstPage}>
                <PaginationLink previous onClick={() => loadPage(data.pageNumber - 1)}/>
              </PaginationItem>
              {
                calculateMessageArrayForPaging().map((index) => {
                  return (
                    <PaginationItem key={`ftpLoglist_${index}`} active={index === data.pageNumber + 1}>
                      <PaginationLink onClick={() => loadPage(index - 1)}>{index}</PaginationLink>
                    </PaginationItem>
                  );
                })
              }
              <PaginationItem disabled={data.lastPage}>
                <PaginationLink next onClick={() => loadPage(data.pageNumber + 1)}/>
              </PaginationItem>
              <PaginationItem disabled={data.lastPage}>
                <PaginationLink last onClick={() => loadPage(data.totalPages - 1)}/>
              </PaginationItem>
          </Pagination>
        </div>}
        <FtpLogModalView 
          content={content}
          loading={contentLoading}
          show={this.state.showModal}
          onHide={handleCloseModal}
        />
      </div>
    );
  }
}

const mapStateToProps = (store: IApplicationState) => {
  return {
    data: store.ftplog.data,
    loading: store.ftplog.loading,
    errors: store.ftplog.errors,
    contentLoading: store.ftplog.loadingContent,
    content: store.ftplog.logContent,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchLogs: (request: IFtpLogFetchRequest) => dispatch(logActions.fetchRequest(request)),
  fetchLogContent: (logId: number) => dispatch(logActions.fetchContentRequest(logId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FtpLogList));
