import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import * as React from "react";
import { Button, Table } from "react-bootstrap";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { IApplicationState } from "../store";
import * as messageActions from "../store/message/actions";
import { IMessageQueryResult, IMessageFetchRequest, IMessageContentFetchRequest, IMessage, MESSAGE_DIRECTION, MESSAGE_ORIGIN, TRANSMISSION_STATUS, IRetransmitRequest } from "../store/message";
import { redoIcon, openIcon } from "../styles/icons";
import { faRetweet } from '@fortawesome/free-solid-svg-icons';
import LoadingSpinner from "./LoadingSpinner";
import MessageContentModalView from "./MessageContentModalView";
import * as settingActions from "../store/settings/actions";
import { IFrontendSettings } from "../store/settings";

// Create the containers interface
interface IPropertiesFromState {
  data: IMessageQueryResult;
  loading: boolean;
  errors?: string;
  contentLoading: boolean;
  content?: string;
  settings?: IFrontendSettings;
}

interface IPropertiesFromDispatch {
  fetchMessages: typeof messageActions.fetchRequest;
  fetchMessageContent: typeof messageActions.fetchMessageContent;
  retransmitRequest: typeof messageActions.retransmitRequest;
  fetchSettingsRequest: typeof settingActions.fetchRequest;
}

interface IMessageListParameters {
  instituteId: number;
}

interface IMessageListState {
  showModal: boolean;
}

class MessageList extends React.Component<IPropertiesFromState & IPropertiesFromDispatch & IMessageListParameters & WithTranslation, IMessageListState> {

  public readonly state: IMessageListState = {
    showModal: false,
  };

  public componentDidMount() {
    this.props.fetchSettingsRequest();
  }

  public render() {
    const { data, loading, errors, t, instituteId, fetchMessages, retransmitRequest, fetchMessageContent, settings } = this.props;

    const loadPage = (pageNumber: number) => {
      fetchMessages({instituteId: instituteId, pageSize: 10, page: pageNumber});
    };

    const handleCloseModal = () => {
      this.setState({ showModal: false });
    };

    const openModal = (message: IMessage) => {
      fetchMessageContent({ instituteId: instituteId, message: message });
      this.setState({ showModal: true});
    }

    const calculateMessageArrayForPaging = () => {
      const maxPages = 20;
      var startPage = 0;
      var endPage = 0;
      if (data.totalPages <= maxPages) {
        startPage = 1;
        endPage = data.totalPages;
      } else {
        const maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
        const maxPagesAfterCurrentPage = Math.floor(maxPages / 2) - 1;
        if (data.pageNumber <= maxPagesBeforeCurrentPage) {
          // current page near the start
          startPage = 1;
          endPage = maxPages;
        } else if (data.pageNumber + maxPagesAfterCurrentPage >= data.totalPages) {
          // current page near the end
          startPage = data.totalPages - maxPages + 1;
          endPage = data.totalPages;
        } else {
          // current page is somewhere in the middle
          startPage = data.pageNumber - maxPagesBeforeCurrentPage;
          endPage = data.pageNumber + maxPagesAfterCurrentPage;
        }
      }
      return Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);
    };

    const getStatusEnumKey = (message: IMessage) => {
      if (message.origin === MESSAGE_ORIGIN.ELDA) {
        return `pages.messages.statusEnum.${message.status}`;
      }
      if (message.direction === MESSAGE_DIRECTION.INSTITUTE) {
        return `pages.messages.statusEnum.${message.status}`;
      }
      if (message.eldaMessageStatus) {
        return `pages.messages.statusEnum.${message.eldaMessageStatus}`;
      }
      if (message.status === TRANSMISSION_STATUS.TRANSMIT) {
        return 'pages.messages.statusEnum.WAIT_FOR_MERGE';
      }
      return `pages.messages.statusEnum.${message.status}`;
    };

    if (loading) {
      return <LoadingSpinner i18nKey="progress.fetching" />;
    }
    if (errors !== undefined) {
      return <div><b>{t("pages.messages.loadError")}</b></div>
    }
    if (instituteId === -1) {
      return <div>{t("pages.messages.selectOrigin")}</div>
    }
    if (!data || data.empty === true) {
      return <div>{t("pages.messages.noData")}</div>;
    }
    return (
      <div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>{t("pages.messages.date")}</th>
              <th>{t("pages.messages.fileName")}</th>
              <th>{t("pages.messages.traeger")}</th>
              <th>{t("pages.messages.mart")}</th>
              {instituteId === 0 && <th>{t("pages.messages.fileNr")}</th> }
              {instituteId > 0 &&
                <>
                <th>{t("pages.messages.fileNrInstitute")}</th>
                <th>{t("pages.messages.fileNrElda")}</th>
                </>
              }
              <th>{t("pages.messages.direction")}</th>
              <th>{t("pages.messages.status")}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {data && data.elements.map((message) => {
              return (
                <tr key={message.id}>
                  <td>{moment(message.date).format("DD.MM.YYYY HH:mm:ss")}</td>
                  <td>{message.fileName}</td>
                  <td title={message.traegerName}>{message.traegerShortname}</td>
                  <td>{message.mart}</td>
                  {instituteId === 0 && <td>{message.fileNr + (message.parted ? " (" + message.messagePart + ")" : "")}</td>}
                  {instituteId > 0 && 
                    <>
                    <td>{message.fileNr + (message.parted ? " (" + message.messagePart + ")" : "")}</td>
                    <td>{message.eldaMessageId ? message.eldaMessageFileNr + (message.parted ? " (" + message.eldaMessagePart + ")" : "") : "-"}</td>
                    </>
                  }
                  <td>{t(`pages.messages.directionEnum.${message.direction}`)}</td>
                  <td title={message.transmissionError}>{t(getStatusEnumKey(message)) + (message.mart !== 'MTT' && message.protokollnummer ? " (" + message.protokollnummer + ")" : "")}</td>
                  <td>
                    <div>
                      {((settings && !settings.deleteEdikurMessages) || message.mart === 'MTT') && 
                          <Button variant="link" onClick={() => openModal(message)} title={t("pages.messages.showContent")}>
                            <FontAwesomeIcon icon={openIcon} pull="right"/>
                          </Button>
                      }
                      {settings && !settings.deleteEdikurMessages && 
                        <>
                          {instituteId > 0 && message.direction === MESSAGE_DIRECTION.INSTITUTE && message.status === 'TRANSMIT' &&
                            <Button variant="link" onClick={() => retransmitRequest({instituteId: instituteId, message: message, newFileNr: false})} title={t("pages.messages.retransmit")}>
                              <FontAwesomeIcon icon={redoIcon} pull="right"/>
                            </Button>
                          }
                          {instituteId === 0 && message.direction === MESSAGE_DIRECTION.ELDA && message.status === 'TRANSMIT' &&
                            <Button variant="link" onClick={() => retransmitRequest({instituteId: 0, message: message, newFileNr: false})} title={t("pages.messages.retransmit")}>
                              <FontAwesomeIcon icon={redoIcon} pull="right"/>
                            </Button>
                          }
                          {instituteId === 0 && message.direction === MESSAGE_DIRECTION.ELDA && message.status === 'TRANSMIT' &&
                            <Button variant="link" onClick={() => retransmitRequest({instituteId: 0, message: message, newFileNr: true})} title={t("pages.messages.retransmitFresh")}>
                              <FontAwesomeIcon icon={faRetweet} pull="right"/>
                            </Button>
                          }
                        </>
                      }
                    </div> 
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        {data && data.totalPages > 1 && <div>
          <Pagination listClassName="rowNoPadding">
              <PaginationItem disabled={data.firstPage}>
                <PaginationLink first onClick={() => loadPage(0)}/>
              </PaginationItem>
              <PaginationItem disabled={data.firstPage}>
                <PaginationLink previous onClick={() => loadPage(data.pageNumber - 1)}/>
              </PaginationItem>
              {
                calculateMessageArrayForPaging().map((index) => {
                  return (
                    <PaginationItem key={`messagePage_${index}`} active={index === data.pageNumber + 1}>
                      <PaginationLink onClick={() => loadPage(index - 1)}>{index}</PaginationLink>
                    </PaginationItem>
                  );
                })
              }
              <PaginationItem disabled={data.lastPage}>
                <PaginationLink next onClick={() => loadPage(data.pageNumber + 1)}/>
              </PaginationItem>
              <PaginationItem disabled={data.lastPage}>
                <PaginationLink last onClick={() => loadPage(data.totalPages - 1)}/>
              </PaginationItem>
          </Pagination>
        </div>}
        <MessageContentModalView
          content={this.props.content}
          loading={this.props.contentLoading}
          show={this.state.showModal}
          onHide={handleCloseModal}
        />
      </div>
    );
  }
}

// Grab the characters from the store and make them available on props
const mapStateToProps = (store: IApplicationState) => {
  return {
    data: store.message.data,
    loading: store.message.loading,
    errors: store.message.errors,
    contentLoading: store.message.loadingContent,
    content: store.message.messageContent,
    settings: store.settings.data,
  };
};

// Mapping the actions to the props to allow calling them in the component
const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchMessages: (request: IMessageFetchRequest) => dispatch(messageActions.fetchRequest(request)),
  retransmitRequest: (request: IRetransmitRequest) => dispatch(messageActions.retransmitRequest(request)),
  fetchMessageContent: (request: IMessageContentFetchRequest) => dispatch(messageActions.fetchMessageContent(request)),
  fetchSettingsRequest: () => dispatch(settingActions.fetchRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MessageList));
