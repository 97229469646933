export interface IEldaEndpoint {
  id: number;
  version: number;
  url: string;
  port: number;
  username: string;
  password: string;
  ftpsAsctive: boolean;
  active: boolean;
  endpointMode: string;
  warnIncative: boolean;
  errorInactive: boolean;
}

export enum EndpointActionTypes {
  FETCH_REQUEST = "@@endpoint/FETCH_REQUEST",
  FETCH_SUCCESS = "@@endpoint/FETCH_SUCCESS",
  FETCH_ERROR = "@@endpoint/FETCH_ERROR",
  SAVE_REQUEST = "@@endpoint/SAVE_REQUEST",
  SAVE_SUCCESS = "@@endpoint/SAVE_SUCCESS",
  SAVE_ERROR = "@@endpoint/SAVE_ERROR",
  TEST_REQUEST = "@@endpoint/TEST_REQUEST",
  DELETE_REQUEST = "@@endpoint/DELETE_REQUEST",
  DELETE_SUCCESS = "@@endpoint/DELETE_SUCCESS",
  DELETE_ERROR = "@@endpoint/DELETE_ERROR",
}

export interface IEndpointState {
  readonly loading: boolean;
  readonly data: IEldaEndpoint[];
  readonly errors?: string;
}

export enum ELDA_ENDPOINT_MODE {
  INACTIVE = "INACTIVE",
  SEND = "SEND",
  RECEIVE = "RECEIVE",
  FULL = "FULL",
}
