import { Reducer } from "redux";

import { FtpLogActionTypes, IFtpLogState, emptyQueryResult } from "./types";

const initialState: IFtpLogState = {
  data: emptyQueryResult,
  errors: undefined,
  loading: false,
  loadingContent: false,
  logContent: undefined,
};

const reducer: Reducer<IFtpLogState> = (state = initialState, action) => {
  switch (action.type) {
    case FtpLogActionTypes.FETCH_REQUEST: {
      return { ...state, errors: undefined, loading: true, data: emptyQueryResult };
    }
    case FtpLogActionTypes.FETCH_SUCCESS: {
      return { ...state, errors: undefined, loading: false, data: action.payload };
    }
    case FtpLogActionTypes.FETCH_ERROR: {
      return { ...state, loading: false, errors: action.payload };
    }
    case FtpLogActionTypes.FETCH_CONTENT_REQUEST: {
      return { ...state, loadingContent: true, logContent: undefined };
    }
    case FtpLogActionTypes.FETCH_CONTENT_SUCCESS: {
      return { ...state, loadingContent: false, logContent: action.payload };
    }
    case FtpLogActionTypes.FETCH_CONTENT_ERROR: {
      return { ...state, loadingContent: false, logContent: undefined };
    }
    default: {
      return state;
    }
  }
};

export { reducer as ftpLogReducer };
