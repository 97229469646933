import * as React from "react";
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap";
import { withTranslation, WithTranslation } from "react-i18next";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { initialize } from "redux-form";
import { ILoginAttempt } from "../store/authentication/types";
import BootstrapFormGroup from "./BootstrapFormGroup";

interface ILoginModalFormProps {
  show: boolean;
  onHide: () => void;
}

class LoginModalForm extends React.Component<InjectedFormProps<ILoginAttempt, ILoginModalFormProps> & WithTranslation & ILoginModalFormProps> {
  public render() {
    const { pristine, submitting, handleSubmit, t, show, onHide } = this.props;
    return (
      <Modal show={show} onHide={onHide}>
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <ModalTitle>{t("pages.login.title")}</ModalTitle>
          </Modal.Header>
          <ModalBody>
            <Field
                name="username"
                component={BootstrapFormGroup}
                label = {t("pages.login.username")}
                placeholder = {t("pages.login.editor.userNamePlaceholder")}
            />
            <Field
                name="password"
                component={BootstrapFormGroup}
                label = {t("pages.login.password")}
                placeholder = {t("pages.login.editor.passwordPlaceholder")}
                type = "password"
            />
          </ModalBody>
          <ModalFooter>
            <Button variant="primary" type="submit" disabled={pristine || submitting}>
              {t("form.general.login")}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}

export const initLoginModalFormAction = (loginAttempt: Partial<ILoginAttempt>) => {
  return initialize("loginFormModal", loginAttempt);
};

export default reduxForm<ILoginAttempt, ILoginModalFormProps>({
  form: "loginFormModal",
  enableReinitialize: true,
})(withTranslation()(LoginModalForm));
