import { applyMiddleware, createStore, Store, Middleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga'
import logger from 'redux-logger';
import { IApplicationState, rootReducer, rootSaga } from './store';

export default function configureStore(): Store<IApplicationState, any> {
  const sagaMiddleware = createSagaMiddleware()
  const middlewares: Middleware[] = [];
  middlewares.push(sagaMiddleware);
  /* Add logger in development environments */
  if (process.env.NODE_ENV === `development`) {
      middlewares.push(logger);
  }
  let middleware = applyMiddleware(...middlewares);
  /* Add dev tools in development environments */
  if (process.env.NODE_ENV === `development`) {
    middleware = composeWithDevTools(middleware);
  }
  const store = createStore(
    rootReducer,
    undefined,
    middleware
  )
  sagaMiddleware.run(rootSaga);
  return store;
}
