import * as React from "react";
import { FormGroup, FormLabel, FormSelect, FormText } from "react-bootstrap";
import { withTranslation, WithTranslation } from "react-i18next";
import { WrappedFieldProps } from "redux-form";

interface ISelectOption {
  value: string;
  label: string;
}

interface IFormConfig {
  label: string;
  placeholder: string;
  explanation?: string;
  options: ISelectOption[];
  horizontal?: boolean;
}

class BootstrapSelectFormGroup extends React.Component<IFormConfig & WrappedFieldProps & WithTranslation> {
  public render() {
    const { label, placeholder, explanation, input, options, horizontal, t } = this.props;
    return (
      <FormGroup className={horizontal ? "row" : ""}>
        {label && <FormLabel className={horizontal ? "col-sm-2" : ""}>{ label }</FormLabel>}
        <FormSelect className={horizontal ? "col-sm-10" : ""} placeholder={ placeholder } {...input}>
          {placeholder && <option>{placeholder}</option>}
          {options.map((option) => {
            return (<option key={option.value} value={option.value}>{t(option.label)}</option>);
          })}
        </FormSelect>
        {explanation && <FormText className="text-muted">{explanation}</FormText>}
      </FormGroup>
    );
  }
}

export default withTranslation()(BootstrapSelectFormGroup);
