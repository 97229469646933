import React, { FunctionComponent } from "react";
import { connect } from "react-redux";
import { Redirect, Route, RouteComponentProps, RouteProps } from "react-router-dom";
import LoadingSpinner from "./components/LoadingSpinner";
import { IApplicationState } from "./store";

interface IPrivateRouteProps extends RouteProps {
  loading: boolean;
  authenticated: boolean;
  component:
  | React.ComponentType<RouteComponentProps<any>>
  | React.ComponentType<any>;
}

const PrivateRoute: FunctionComponent<IPrivateRouteProps> = ({
  component: Component,
  loading,
  authenticated,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        loading ? <LoadingSpinner i18nKey="progress.verifying" /> :
          authenticated ? (<Component {...props} />) : (
            <Redirect
              to={{
                pathname: "/login",
              }}
            />
          )
      }
    />
  );
};

const mapStateToProps = (store: IApplicationState) => {
  return {
    authenticated: store.authentication.loggedIn,
    loading: store.authentication.loading,
  };
};

export default connect(mapStateToProps)(PrivateRoute);
