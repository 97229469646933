import moment from "moment";
import * as React from "react";
import { Container, Table } from "react-bootstrap";
import { IAuthentication } from "../store/authentication";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { IApplicationState } from "../store";
import * as messageActions from "../store/message/actions";
import * as notification from "../store/notifications";
import { IMessage, IMessageLookupRequest } from "../store/message";
import LookupForm from "../form/LookupForm";
import { withTranslation, WithTranslation } from "react-i18next";
import * as settingActions from "../store/settings/actions";
import { IFrontendSettings } from "../store/settings";

interface IPropertiesFromState {
  authentication?: IAuthentication;
  error?: string;
  messages: IMessage[];
  settings?: IFrontendSettings;
}

interface IPropertiesFromDispatch {
  lookupMessage: typeof messageActions.lookupRequest;
  clearMessagesCommand: typeof messageActions.clearMessages;
  validationErrorException: typeof notification.validationErrorNotification;
  fetchSettingsRequest: typeof settingActions.fetchRequest;
}

interface IMessagePageState {
  selectedPage: string;
  instituteId: number;
}

class LookupPage extends React.Component<IPropertiesFromState & IPropertiesFromDispatch & WithTranslation, IMessagePageState> {

  public readonly state: IMessagePageState = {
    selectedPage: "",
    instituteId: -1,
  }

  public componentDidMount() {
    this.props.fetchSettingsRequest();
    if (this.props.authentication == null) {
      return;
    }
    this.props.clearMessagesCommand();
    if (this.props.authentication.sysadmin) {
      this.setState({instituteId: -1});
    } else {
      this.setState({instituteId: this.props.authentication.instituteId});
    }
  }

  public render() {
    const { t, lookupMessage, validationErrorException, error, messages, settings } = this.props;

    const lookup = (request: IMessageLookupRequest) => {
      if (request.mart === undefined || request.mart === '') {
        validationErrorException("lookup.missingMart");
        return;
      }
      if (request.eldaFileNr === undefined || request.eldaFileNr === 0) {
        validationErrorException("lookup.missingFileNr");
        return;
      }
      lookupMessage(request);
    }

    return (
      <>
        <Container fluid={true}>
          <LookupForm 
            onSubmit={lookup}
            showVsnr={settings ? !settings.deleteEdikurMessages : false}
          />
          {error && <div><b>{t(error)}</b></div>}
          {messages && messages.length > 0 && 
            <Table striped bordered hover>
            <thead>
              <tr>
                <th>{t("pages.messages.institute")}</th>
                <th>{t("pages.messages.date")}</th>
                <th>{t("pages.messages.traeger")}</th>
                <th>{t("pages.messages.mart")}</th>
                <th>{t("pages.messages.fileNrInstitute")}</th>
                <th>{t("pages.messages.fileNrElda")}</th>
              </tr>
            </thead>
            <tbody>
              {messages.map((message) => {
                return (
                  <tr key={message.id}>
                    <td>{message.instituteName}</td>
                    <td>{moment(message.date).format("DD.MM.YYYY HH:mm:ss")}</td>
                    <td>{message.traegerName}</td>
                    <td>{message.mart}</td>
                    <td>{message.fileNr}</td>
                    <td>{message.eldaMessageId !== undefined ? message.eldaMessageFileNr : "-"}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          }
        </Container>
      </>
    );
  }
}

const mapStateToProps = (store: IApplicationState) => {
  return {
    error: store.message.errors,
    messages: store.message.data.elements,
    authentication: store.authentication.authentication,
    settings: store.settings.data,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  lookupMessage: (request: IMessageLookupRequest) => dispatch(messageActions.lookupRequest(request)),
  clearMessagesCommand: () => dispatch(messageActions.clearMessages()),
  validationErrorException: (key: string) => dispatch(notification.validationErrorNotification(key)),
  fetchSettingsRequest: () => dispatch(settingActions.fetchRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LookupPage));
