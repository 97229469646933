import { Reducer } from "redux";
import { IRuleState, RuleActionTypes } from "./types";

const initialState: IRuleState = {
  data: undefined,
  errors: undefined,
  loading: false,
};

const reducer: Reducer<IRuleState> = (state = initialState, action) => {
  switch (action.type) {
    case RuleActionTypes.FETCH_REQUEST: {
      return { ...state, loading: true };
    }
    case RuleActionTypes.FETCH_SUCCESS: {
      return { ...state, loading: false, data: action.payload };
    }
    case RuleActionTypes.FETCH_ERROR: {
      return { ...state, loading: false, errors: action.payload };
    }
    case RuleActionTypes.SAVE_SUCCESS: {
      return { ...state, data: action.payload };
    }
    case RuleActionTypes.ADD_RULE: {
      if (state.data === undefined) {
        return state;
      }
      const newRule = {
        edikurVersion: "06",
        criteria: "",
        value: "",
        instituteFk: 0
      };
      return {
        ...state,
        data: {
          ...state.data,
          rules: [newRule, ...state.data?.rules],
        }
      };
    }
    case RuleActionTypes.REMOVE_RULE: {
      if (action.payload < 0 || (state.data && action.payload >= state.data?.rules.length)) {
        return state;
      }
      if (state.data === undefined) {
        return state;
      }
      let rulesCopy = [...state.data?.rules];
      rulesCopy.splice(action.payload, 1);
      return {
        ...state,
        data: {
          ...state.data,
          rules: rulesCopy,
        }
      };
    }
    case RuleActionTypes.MOVE_RULE_UP: {
      if (action.payload < 1 || (state.data && action.payload >= state.data?.rules.length)) {
        return state;
      }
      if (state.data === undefined) {
        return state;
      }
      let rulesCopy = [...state.data?.rules];
      rulesCopy.splice(action.payload - 1, 0, rulesCopy.splice(action.payload, 1)[0]);
      return {
        ...state,
        data: {
          ...state.data,
          rules: rulesCopy,
        }
      };
    }
    case RuleActionTypes.MOVE_RULE_DOWN: {
      if (action.payload < 0 || (state.data && action.payload >= state.data?.rules.length - 1)) {
        return state;
      }
      if (state.data === undefined) {
        return state;
      }
      let rulesCopy = [...state.data?.rules];
      rulesCopy.splice(action.payload + 1, 0, rulesCopy.splice(action.payload, 1)[0]);
      return {
        ...state,
        data: {
          ...state.data,
          rules: rulesCopy,
        }
      };
    }
    case "@@redux-form/CHANGE": {
      if (action.meta.form !== "ruleForm") {
        return state;
      }
      if (state.data === undefined) {
        return state;
      }
      if (action.meta.field === "defaultInstitute") {
        return {
          ...state,
          data: {
            ...state.data,
            defaultInstitute: Number(action.payload)
          }
        };
      }
      if (action.meta.field.indexOf("[") === -1) {
        return state;
      }
      let index = parseInt(action.meta.field.match(/\[(.*?)\]/).pop());
      let field = action.meta.field.split(".")[1];
      if (index === -1 || !field) {
        return state;
      }
      let rulesCopy = [...state.data?.rules];
      if (field === "criteria") {
        rulesCopy[index].criteria = action.payload;
      }
      if (field === "value") {
        rulesCopy[index].value = action.payload;
      }
      if (field === "edikurVersion") {
        rulesCopy[index].edikurVersion = action.payload;
      }
      if (field === "instituteFk") {
        rulesCopy[index].instituteFk = Number(action.payload).valueOf();
      }
      return {
        ...state,
        data: {
          ...state.data,
          rules: rulesCopy,
        }
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as ruleReducer };
