import { action } from "typesafe-actions";
import { IUser, IPasswordChangeRequest, UserActionTypes } from "./types";

export const fetchRequest = (includeDeleted: boolean) => action(UserActionTypes.FETCH_REQUEST, includeDeleted);
export const fetchSuccess = (data: IUser[]) => action(UserActionTypes.FETCH_SUCCESS, data);
export const fetchError = (message: string) => action(UserActionTypes.FETCH_ERROR, message);

export const saveRequest = (data: IUser) => action(UserActionTypes.SAVE_REQUEST, data);
export const saveSuccess = (result: IUser) => action(UserActionTypes.SAVE_SUCCESS, result);
export const saveError = (message: string) => action(UserActionTypes.SAVE_ERROR, message);

export const passwordChangeRequest = (request: IPasswordChangeRequest) => action(UserActionTypes.PASSWORD_CHANGE_REQUEST, request);
export const passwordChangeSuccess = (request: IPasswordChangeRequest) => action(UserActionTypes.PASSWORD_CHANGE_SUCCESS, request);
export const passwordChangeError = (message: string) => action(UserActionTypes.PASSWORD_CHANGE_ERROR, message);
