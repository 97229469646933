export interface IFtpLogEntry {
  id: number;
  connectTime: string;
  success: boolean;
  warnMessage: string;
  errorMessage: string;
  eldaRetContent: string;
  uploads: string[];
  downloads: string[];
}

export interface IFtpLogQueryResult {
  pageNumber: number;
  totalPages: number;
  elementsPerPage: number;
  totalElementCount: number;
  firstPage: boolean;
  lastPage: boolean;
  empty: boolean;
  elements: IFtpLogEntry[];
}

export interface IFtpLogFetchRequest {
  instituteId: number;
  page: number;
  pageSize: number;
}

export enum FtpLogActionTypes {
  FETCH_REQUEST = "@@ftplog/FETCH_REQUEST",
  FETCH_SUCCESS = "@@ftplog/FETCH_SUCCESS",
  FETCH_ERROR = "@@ftplog/FETCH_ERROR",

  FETCH_CONTENT_REQUEST = "@@ftplog/FETCH_CONTENT_REQUEST",
  FETCH_CONTENT_SUCCESS = "@@ftplog/FETCH_CONTENT_SUCCESS",
  FETCH_CONTENT_ERROR = "@@ftplog/FETCH_CONTENT_ERROR",
}

export interface IFtpLogState {
  readonly loading: boolean;
  readonly data: IFtpLogQueryResult;
  readonly errors?: string;
  readonly loadingContent: boolean;
  readonly logContent?: string;
}

export const emptyQueryResult: IFtpLogQueryResult = {
  pageNumber: 0,
  totalPages: 0,
  elementsPerPage: 0,
  totalElementCount: 0,
  firstPage: false,
  lastPage: false,
  empty: true,
  elements: []
}
