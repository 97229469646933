import { Reducer } from "redux";
import { SettingActionTypes, ISettingsState } from "./types";

const initialState: ISettingsState = {
  data: undefined,
  errors: undefined,
  loading: false,
};

const reducer: Reducer<ISettingsState> = (state = initialState, action) => {
  switch (action.type) {
    case SettingActionTypes.FETCH_REQUEST: {
      return { ...state, errors: undefined, loading: true };
    }
    case SettingActionTypes.FETCH_SUCCESS: {
      return { ...state, loading: false, data: action.payload };
    }
    case SettingActionTypes.FETCH_ERROR: {
      return { ...state, loading: false, errors: action.payload };
    }
    default: {
      return state;
    }
  }
};

export { reducer as settingReducer };
