export interface IFrontendSettings {
  noDefaultInstitute: boolean;
  deleteEdikurMessages: boolean;
}

export enum SettingActionTypes {
  FETCH_REQUEST = "@@settings/FETCH_REQUEST",
  FETCH_SUCCESS = "@@settings/FETCH_SUCCESS",
  FETCH_ERROR = "@@settings/FETCH_ERROR",
}

export interface ISettingsState {
  readonly loading: boolean;
  readonly data?: IFrontendSettings;
  readonly errors?: string;
}
