import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { Button, Table } from "react-bootstrap";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import EldaEndpointModalForm, { initEldaEndpointModalFormAction } from "../form/EldaEndpointModalForm";
import { IApplicationState } from "../store";
import { IEldaEndpoint } from "../store/endpoint";
import * as endpointActions from "../store/endpoint/actions";
import { editIcon, trashIcon } from "../styles/icons";
import LoadingSpinner from "./LoadingSpinner";

interface IPropertiesFromState {
  data: IEldaEndpoint[];
  loading: boolean;
  errors?: string;
  currentData?: Partial<IEldaEndpoint>;
}

interface IPropertiesFromDispatch {
  fetchRequest: typeof endpointActions.fetchRequest;
  saveRequest: typeof endpointActions.saveRequest;
  deleteRequest: typeof endpointActions.deleteRequest;
  testEndpoint: typeof endpointActions.testRequest;
  initEldaEndpointForm: typeof initEldaEndpointModalFormAction;
}

interface IEldaEndpointListState {
  createEndpoint: boolean;
  showEditModal: boolean;
  lastSelectedEndpoint: number;
}

class EldaEndpointList extends React.Component<IPropertiesFromState & IPropertiesFromDispatch & WithTranslation, IEldaEndpointListState> {
  public readonly state: IEldaEndpointListState = {
    createEndpoint: true,
    showEditModal: false,
    lastSelectedEndpoint: 0,
  };

  public componentDidMount() {
    this.props.fetchRequest();
  }

  public render() {
    const { data, loading, t, saveRequest, deleteRequest, initEldaEndpointForm, testEndpoint, currentData } = this.props;

    const handleCloseModal = () => {
      this.setState({ showEditModal: false });
    };

    const handleCreate = () => {
      initEldaEndpointForm({});
      this.setState({ showEditModal: true, createEndpoint: true });
    };

    const handleEdit = (endpoint: IEldaEndpoint) => {
      initEldaEndpointForm(endpoint);
      this.setState({ showEditModal: true, createEndpoint: false});
    };

    const submitChanges = (endpoint: IEldaEndpoint) => {
      saveRequest(endpoint);
      handleCloseModal();
    };

    const testEldaEndpoint = () => {
      if (currentData === undefined) {
        return;
      }
      testEndpoint(currentData);
    }

    if (loading) {
      return <LoadingSpinner i18nKey="progress.fetching" />;
    }
    return (
      <div>
        {(!data || data.length === 0) && <div className="mb-3">{t("pages.endpoint.noData")}</div>}
        {data && data.length > 0 && <Table striped bordered hover>
          <thead>
            <tr>
              <th>{t("pages.endpoint.url")}</th>
              <th>{t("pages.endpoint.port")}</th>
              <th>{t("pages.endpoint.username")}</th>
              <th>{t("pages.endpoint.endpointMode")}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {data.map((endpoint) => {
              return (
                <tr key={endpoint.id}>
                  <td>{endpoint.url}</td>
                  <td>{endpoint.port}</td>
                  <td>{endpoint.username}</td>
                  <td>{t(`pages.endpoint.endpointModes.${endpoint.endpointMode}`)}</td>
                  <td>
                    <div>
                      <Button variant="link" onClick={() => handleEdit(endpoint)} title={t("pages.endpoint.editEndpoint")}>
                        <FontAwesomeIcon icon={editIcon} pull="right"/>
                      </Button>
                      <Button variant="link" onClick={() => deleteRequest(endpoint)} title={t("pages.endpoint.deleteEndpoint")}>
                        <FontAwesomeIcon icon={trashIcon} pull="right"/>
                      </Button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>}
        <Button variant="outline-info" onClick={handleCreate}>
          {t("pages.endpoint.createEndpoint")}
        </Button>
        <EldaEndpointModalForm
          onSubmit={(submitChanges)}
          initialValues={{}}
          createEndpoint={this.state.createEndpoint}
          show={this.state.showEditModal}
          testEndpoint={testEldaEndpoint}
          onHide={handleCloseModal}
        />
      </div>
    ); 
  }
}

// Grab the characters from the store and make them available on props
const mapStateToProps = (store: IApplicationState) => {
  return {
    data: store.endpoint.data,
    loading: store.endpoint.loading,
    errors: store.endpoint.errors,
    currentData: store.form.eldaEndpointFormModal !==  undefined ? store.form.eldaEndpointFormModal.values : {},
  };
};

// Mapping the actions to the props to allow calling them in the component
const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchRequest: () => dispatch(endpointActions.fetchRequest()),
  saveRequest: (endpoint: IEldaEndpoint) => dispatch(endpointActions.saveRequest(endpoint)),
  deleteRequest: (endpoint: IEldaEndpoint) => dispatch(endpointActions.deleteRequest(endpoint)),
  testEndpoint: (endpoint: Partial<IEldaEndpoint>) => dispatch(endpointActions.testRequest(endpoint)),
  initEldaEndpointForm: (endpoint: Partial<IEldaEndpoint>) => dispatch(initEldaEndpointModalFormAction(endpoint)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EldaEndpointList));
