import { reducer as toastrReducer, ToastrState } from "react-redux-toastr";
import { combineReducers } from "redux";
import { FormStateMap, reducer as reduxFormReducer } from "redux-form";
import { all, fork } from "redux-saga/effects";
import { authenticationReducer, authenticationSaga, IAuthenticationState } from "./authentication";
import { IInstituteState, instituteReducer, instituteSaga  } from "./institute";
import { IUserState, userReducer, userSaga } from "./user";
import { IMessageState, messageReducer, messageSaga } from './message';
import { IEndpointState, endpointReducer, endpointSaga } from './endpoint';
import { IRuleState, ruleReducer, ruleSaga } from './rules';
import { IFtpLogState, ftpLogReducer, ftpLogSaga } from './ftplog';
import { ISettingsState, settingReducer, settingsSaga } from './settings';

export interface IApplicationState {
  authentication: IAuthenticationState;
  institute: IInstituteState;
  user: IUserState;
  endpoint: IEndpointState;
  message: IMessageState;
  rules: IRuleState;
  ftplog: IFtpLogState;
  form: FormStateMap;
  settings: ISettingsState;
  toastr: ToastrState;
}

export const rootReducer = combineReducers<IApplicationState>({
  authentication: authenticationReducer,
  institute: instituteReducer,
  user: userReducer,
  endpoint: endpointReducer,
  message: messageReducer,
  rules: ruleReducer,
  ftplog: ftpLogReducer,
  form: reduxFormReducer,
  settings: settingReducer,
  toastr: toastrReducer,
});

export function* rootSaga() {
  yield all([
    fork(authenticationSaga),
    fork(instituteSaga),
    fork(userSaga),
    fork(endpointSaga),
    fork(messageSaga),
    fork(ruleSaga),
    fork(settingsSaga),
    fork(ftpLogSaga),
  ]);
}
