export interface IMessage {
  id: number;
  version: number;
  date: string;
  instituteId: number;
  instituteName: string;
  origin: string;
  direction: string;
  vstrId: string;
  traegerName: string;
  traegerShortname: string;
  mart: string;
  fileNr: number;
  messagePart: number;
  edikurVersion: string;
  fileName: string;
  status: string;
  eldaMessageId?: number;
  eldaMessageFileNr?: number;
  eldaMessagePart?: number;
  eldaMessageStatus?: string;
  transmissionError?: string;
  protokollnummer?: number;
  parted: boolean;
}

export interface IMessageQueryResult {
  pageNumber: number;
  totalPages: number;
  elementsPerPage: number;
  totalElementCount: number;
  firstPage: boolean;
  lastPage: boolean;
  empty: boolean;
  elements: IMessage[];
}

export interface IMessageFetchRequest {
  instituteId: number;
  page: number;
  pageSize: number;
}

export interface IRetransmitRequest {
  instituteId: number;
  message: IMessage;
  newFileNr: boolean;
}

export interface IMessageContentFetchRequest {
  instituteId: number;
  message: IMessage;
}

export interface IMessageLookupRequest {
  mart: string;
  eldaFileNr: number;
  vsnr?: string;
}

export enum MessageActionTypes {
  FETCH_REQUEST = "@@message/FETCH_REQUEST",
  FETCH_SUCCESS = "@@message/FETCH_SUCCESS",
  FETCH_ERROR = "@@message/FETCH_ERROR",

  RETRANSMIT_REQUEST = "@@message/RETRANSMIT_REQUEST",
  RETRANSMIT_SUCCESS = "@@message/RETRANSMIT_SUCCESS",

  FETCH_CONTENT_REQUEST = "@@message/FETCH_CONTENT_REQUEST",
  FETCH_CONTENT_SUCCESS = "@@message/FETCH_CONTENT_SUCCESS",
  FETCH_CONTENT_ERROR = "@@message/FETCH_CONTENT_ERROR",

  LOOKUP_REQUEST = "@@message/LOOKUP_REQUEST",
  LOOKUP_RESULT = "@@message/LOOKUP_RESULT",
  LOOKUP_ERROR = "@@message/LOOKUP_ERROR",

  CLEAR_MESSAGES = "@@messages/CLEAR_MESSAGES",
}

export interface IMessageState {
  readonly loading: boolean;
  readonly data: IMessageQueryResult;
  readonly errors?: string;
  readonly loadingContent: boolean;
  readonly messageContent?: string;
}
