import { Reducer } from "redux";
import { IInstituteState, InstituteActionTypes } from "./types";

const initialState: IInstituteState = {
  data: [],
  errors: undefined,
  loading: false,
};

const reducer: Reducer<IInstituteState> = (state = initialState, action) => {
  switch (action.type) {
    case InstituteActionTypes.FETCH_REQUEST: {
      return { ...state, loading: true };
    }
    case InstituteActionTypes.FETCH_SUCCESS: {
      return { ...state, loading: false, data: action.payload };
    }
    case InstituteActionTypes.FETCH_ERROR: {
      return { ...state, loading: false, errors: action.payload };
    }
    case InstituteActionTypes.SAVE_SUCCESS: {
      const index = state.data.findIndex((item) => item.id === action.payload.id);
      if (index === -1) {
        return {
          ...state,
          data: state.data.concat(action.payload),
        };
      }
      const updatedData = state.data.map((item, innerIndex) => {
        if (innerIndex !== index) {
          return item;
        }
        return {
          ...item,
          ...action.payload,
        };
      });
      return {
        ...state,
        data: updatedData,
      };
    }
    case InstituteActionTypes.PASSWORD_CHANGE_SUCCESS: {
      const index = state.data.findIndex((item) => item.id === action.payload.instituteId);
      if (index === -1) {
        return state;
      }
      const updatedData = state.data.map((item, innerIndex) => {
        if (innerIndex !== index) {
          return item;
        }
        return {
          ...item,
          ftpPassword: action.payload.password,
        };
      });
      return {
        ...state,
        data: updatedData,
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as instituteReducer };
