export interface IJsonAnswer {
  data: any;
  error?: string;
  statusCode: number;
}

export async function executeRestCallWithJsonAnswer(method: string, path: string, token: string, data?: any): Promise<IJsonAnswer> {
  const response = await executeRestCall(method, path, token, data);
  if (response.status !== 200) {
    return {
      data: {},
      error: await response.text(),
      statusCode: response.status,
    };
  }
  return {
    data: await response.json(),
    error: undefined,
    statusCode: 200,
  };
}

export async function executeRestCall(method: string, path: string, token: string, data?: any): Promise<Response> {
  return await fetch(path, {
    body: JSON.stringify(data),
    headers: {
      //"Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token,
    },
    method,
  });
}
