import * as React from "react";

interface MaxLengthTextProperties {
  text: string;
  maxLength: number;
}

const MaxLengthText: React.FC<MaxLengthTextProperties> = ({ text, maxLength }) => {
  return (
    <div title={text}>{text.length > maxLength ? text.substring(0, maxLength - 3) + "..." : text}</div>
  );
};

export default MaxLengthText;
