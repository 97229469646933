import * as React from "react";
import { Button, Form } from "react-bootstrap";
import { withTranslation, WithTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import { Field, InjectedFormProps, reduxForm  } from "redux-form";
import { initialize } from "redux-form";
import { IMessageLookupRequest } from "../store/message/types";
import BootstrapFormGroup from "./BootstrapFormGroup";
import BootstrapSelectFormGroup from "./BootstrapSelectFormGroup";

interface LookupFormProperties {
    showVsnr: boolean;
}

class LookupForm extends React.Component<InjectedFormProps<IMessageLookupRequest, LookupFormProperties> & LookupFormProperties & WithTranslation> {
    public render() {
        const { pristine, submitting, showVsnr, handleSubmit, t } = this.props;
        return (
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col xs={3}>
                        <Field
                            name="mart"
                            component={BootstrapSelectFormGroup}
                            options={[
                                {label: "R04", value: "R04"},
                                {label: "R05", value: "R05"},
                                {label: "R06", value: "R06"},
                                {label: "R07", value: "R07"},
                                {label: "R08", value: "R08"},
                                {label: "R15", value: "R15"},
                                {label: "R16", value: "R16"},
                                {label: "R17", value: "R17"},
                                {label: "R99", value: "R99"},
                            ]}
                            label={t("pages.messages.lookup.mart")}
                            placeholder={t("pages.messages.lookup.martPlaceholder")}
                        />
                    </Col>
                    <Col xs={3}>
                        <Field
                            name="eldaFileNr"
                            component={BootstrapFormGroup}
                            label = {t("pages.messages.fileNrElda")}
                            placeholder = {t("pages.messages.lookup.fileNrEldaPlaceholder")}
                            type = "number"
                        />
                    </Col>
                    {showVsnr && 
                        <Col xs={3}>
                            <Field
                                name="vsnr"
                                component={BootstrapFormGroup}
                                label = {t("pages.messages.lookup.vsnr")}
                                placeholder = {t("pages.messages.lookup.vsnrPlaceholder")}
                                maxLength = {10}
                            />
                        </Col>
                    }
                    <Col xs={3}>
                        <Button variant="primary" className={"searchButton"} type="submit" disabled={pristine || submitting}>
                            {t("form.general.search")}
                        </Button>
                    </Col>
                </Row>
            </Form>
        );
    }
}

export const initLookupFormAction = (request: Partial<IMessageLookupRequest>) => {
    return initialize("lookupForm", request);
};

export default reduxForm<IMessageLookupRequest, LookupFormProperties>({
    form: "lookupForm",
    enableReinitialize: true,
}) (withTranslation() (LookupForm));
