import * as React from "react";
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap";
import { withTranslation, WithTranslation } from "react-i18next";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { initialize } from "redux-form";
import { IEldaEndpoint } from "../store/endpoint";
import BootstrapCheckbox from "./BootstrapCheckbox";
import BootstrapFormGroup from "./BootstrapFormGroup";
import BootstrapSelectFormGroup from "./BootstrapSelectFormGroup";

interface IEldaEndpointModalFormProps {
  show: boolean;
  onHide: () => void;
  createEndpoint: boolean;
  testEndpoint: () => void;
}

class EldaEndpointModalForm extends React.Component<InjectedFormProps<IEldaEndpoint, IEldaEndpointModalFormProps> & WithTranslation & IEldaEndpointModalFormProps> {
  public render() {
    const { pristine, submitting, reset, handleSubmit, t, show, onHide, createEndpoint, testEndpoint } = this.props;
    return (
      <Modal show={show} onHide={onHide} size={"xl"}>
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <ModalTitle>{t(createEndpoint ? "pages.endpoint.createEndpoint" : "pages.endpoint.editEndpoint")}</ModalTitle>
          </Modal.Header>
          <ModalBody>
            <Field
              name="endpointMode"
              component={BootstrapSelectFormGroup}
              options={[
                  {label: t("pages.endpoint.endpointModes.INACTIVE"), value: "INACTIVE"},
                  {label: t("pages.endpoint.endpointModes.SEND"), value: "SEND"},
                  {label: t("pages.endpoint.endpointModes.RECEIVE"), value: "RECEIVE"},
                  {label: t("pages.endpoint.endpointModes.FULL"), value: "FULL"},
              ]}
              label={t("pages.endpoint.endpointMode")}
            />
            <Field
              name="url"
              component={BootstrapFormGroup}
              label = {t("pages.endpoint.url")}
              placeholder = {t("pages.endpoint.urlPlaceholder")}
            />
            <Field
              name="port"
              component={BootstrapFormGroup}
              label = {t("pages.endpoint.port")}
              placeholder = {t("pages.endpoint.portPlaceholder")}
              type = "number"
            />
            <Field
              name="username"
              component={BootstrapFormGroup}
              label = {t("pages.endpoint.username")}
              placeholder = {t("pages.endpoint.usernamePlaceholder")}
            />
            <Field
              name="password"
              component={BootstrapFormGroup}
              label = {t("pages.endpoint.password")}
              placeholder = {t("pages.endpoint.passwordPlaceholder")}
              type = "password"
            />
            <Field
              name="ftpsActive"
              component={BootstrapCheckbox}
              label = {t("pages.endpoint.ftps")}
              type = "checkbox"
            />
          </ModalBody>
          <ModalFooter>
            <Button variant="info" type="button" onClick={() => testEndpoint()}>
                {t("pages.endpoint.test")}
            </Button>
            <Button variant="secondary" type="button" disabled={pristine || submitting} onClick={reset}>
              {t("form.general.revert")}
            </Button>
            <Button variant="primary" type="submit" disabled={pristine || submitting}>
              {t(createEndpoint ? "form.general.create" : "form.general.update")}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}

export const initEldaEndpointModalFormAction = (endpoint: Partial<IEldaEndpoint>) => {
  return initialize("eldaEndpointFormModal", endpoint);
};

export default reduxForm<IEldaEndpoint, IEldaEndpointModalFormProps>({
  form: "eldaEndpointFormModal",
})(withTranslation()(EldaEndpointModalForm));
