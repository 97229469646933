import * as React from "react";
import { Spinner } from "react-bootstrap";
import { withTranslation, WithTranslation } from "react-i18next";

interface ISpinnerProperties {
  i18nKey: string;
}

const LoadingSpinner: React.FC<WithTranslation & ISpinnerProperties> = ({ t, i18nKey }) => {
  return (
    <div>
      <Spinner animation="border" role="status" />
      <span>{`   ${t(i18nKey)}`}</span>
    </div>
  );
};

export default withTranslation()(LoadingSpinner);
